import { ALL_ARTICLES_REQUEST, ALL_ARTICLES_SUCCESS, ALL_ARTICLES_FAIL, ARTICLE_DETAILS_REQUEST, ARTICLE_DETAILS_SUCCESS, ARTICLE_DETAILS_FAIL } from 'shared/constants/articleConstant';

export const articleReducers = (state = { loading: true, articles: [] }, action) => {
    switch (action.type) {
        case ALL_ARTICLES_REQUEST:
            return { loading: true, articles: [] };
        case ALL_ARTICLES_SUCCESS:
            return { loading: false, articles: action.payload };
        case ALL_ARTICLES_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const articleDetailsReducer = (state = { loading: true, article: {} }, action) => {
    switch (action.type) {
        case ARTICLE_DETAILS_REQUEST:
            return { loading: true };
        case ARTICLE_DETAILS_SUCCESS:
            return { loading: false, article: action.payload };
        case ARTICLE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}