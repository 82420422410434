import Axios from 'axios'
import {
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAIL,
} from 'shared/constants/productConstants'
import axiosUrl from 'libs/axiosUrl'

export const getProducts = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_LIST_REQUEST,
  })

  try {
    const res = await Axios.get(`${axiosUrl}/products`)
    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message })
  }
}

export const getDetailsProduct = (slug) => async (dispatch) => {
  let url = `${axiosUrl}/products/?slug=${slug}`

  dispatch({
    type: PRODUCT_DETAILS_REQUEST,
    payload: slug,
  })

  try {
    const { data } = await Axios.get(url)
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCategories = () => async (dispatch) => {
  dispatch({
    type: CATEGORIES_REQUEST,
  })

  try {
    const { data } = await Axios.get(`${axiosUrl}/categories`)

    dispatch({ type: CATEGORIES_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
