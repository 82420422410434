import React from 'react';
import { Box, Typography, makeStyles, Button } from '@material-ui/core';
import classNames from 'classnames';
import axiosUrl from 'libs/axiosUrl';
import { browserName } from 'react-device-detect';

const BlogCard = ({ postTitle, postContent, thumbnail_image, specialOffer = false }) => {
    const c = useStyles();

    const showOther = (content) => {
        return {
            __html: content
        }
    }

    return (
        <Box display="flex" flexDirection="column" textAlign="center" justifyContent="center" p={3} m={1} className={c.blogCardWrapper}>
            <Box flex={1}>
                <Box className={classNames(c.blogCardImgWrapper)}>
                    {
                        thumbnail_image &&
                        thumbnail_image._id &&
                        <img
                            src={`${axiosUrl}${thumbnail_image.formats.small.url}`}
                            alt="blog card"
                        />
                    }
                    {
                        specialOffer && <img src={thumbnail_image} alt="proizvod" />
                    }
                </Box>

                <Typography
                    variant="h4"
                    className={c.postTitle}
                    gutterBottom={true}
                    dangerouslySetInnerHTML={showOther(postTitle)}
                />

                {
                    postContent && (<Box
                        className={browserName === 'Firefox' || browserName === "Mobile Safari" || browserName === "Silk" || browserName === "Chrome WebView" ?
                            classNames("textColor", c.productMainDescriptionFirefox, c.postContent) :
                            classNames("textColor", c.productMainDescriptionChrome, c.postContent)
                        }
                        dangerouslySetInnerHTML={showOther(postContent)}
                    />)
                }
            </Box>

            {
                !specialOffer && (
                    <Button
                        className={c.btnAddToCart}
                        variant="outlined"
                    >
                        Saznajte više
                    </Button>
                )
            }

        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    blogCardWrapper: {
        flex: 1,
        color: theme.palette.common.text,
        backgroundColor: theme.palette.common.white,
        borderRadius: 10,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    blogCardImgWrapper: {
        "& img": {
            maxWidth: "100%",
            maxHeight: "233px",
            height: "auto",
        },
    },
    postTitle: {
        fontWeight: 700,
        color: theme.palette.common.text,
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    postContent: {
        textAlign: 'left',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        fontSize: "1.1rem !important",
    },
    btnAddToCart: {
        marginTop: 8,
        fontSize: theme.spacing(3),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('xs')]: {
            fontSize: 18,
            width: '100%',
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            margin: theme.spacing(1),
        },
        [theme.breakpoints.up('lg')]: {
            width: 'auto',
        },
        border: `1px solid ${theme.palette.primary.main}`,
        "& path": {
            stroke: theme.palette.common.white,
        },
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
            "& path": {
                stroke: theme.palette.primary.main,
            }
        }
    },
    productMainDescriptionChrome: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
    },
    productMainDescriptionFirefox: {
        display: 'inline-block',
        maxHeight: 90,
        overflowY: 'hidden',
    },
}))

export default BlogCard;