import { CART_ADD_ITEM, CART_CLEAR_ITEMS, CART_REMOVE_ITEM } from "shared/constants/cartConstant";

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload;
            const existItem = state.cartItems.find(x => (x.productId === item.productId) && (x.size === item.size));
            if (existItem) {
                return {
                    ...state,
                    cartItems: state.cartItems.map(x => (x.productId === item.productId) && (x.size === item.size) ? item : x)
                };
            } else {
                return { ...state, cartItems: [...state.cartItems, item] };
            }
        case CART_REMOVE_ITEM:
            return {
                ...state, cartItems: state.cartItems.filter(x => {
                    let item;
                    if (x.productId !== action.payload.productId) {
                        item = x;
                    } else {
                        if ((x.size !== action.payload.size)) {
                            item = x;
                        }
                    }
                    return item;
                })
            }
        case CART_CLEAR_ITEMS:
            return { ...state, cartItems: [] };
        default:
            return state;
    }
}