import { CONTACT_FORM_FAIL, CONTACT_FORM_REQUEST, CONTACT_FORM_SUCCESS } from "shared/constants/contactConstant";

export const contactFormReducer = (state = { loading: false, msg: null }, action) => {
    switch (action.type) {
        case CONTACT_FORM_REQUEST:
            return { loading: true };
        case CONTACT_FORM_SUCCESS:
            return { loading: false, msg: action.payload };
        case CONTACT_FORM_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}