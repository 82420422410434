import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';

import { ProductCardBox, HashTag, LoadingBox, MessageBox } from 'components';

const AllProductsPage = () => {
  const [categoryFilter, setCategoryFilter] = useState({});

  const c = useStyles();
  const history = useHistory();
  const productList = useSelector((state) => state.productList);
  const categoryList = useSelector((state) => state.categories.categoryList);

  const { products, error, loading } = productList;

  useEffect(() => {
    const setAllCategories = () => {
      let initialCategories = {};

      categoryList.forEach((category) => {
        initialCategories[category.name] = false;
      });
      setCategoryFilter(initialCategories);
    };

    setAllCategories();
  }, [categoryList]);

  useEffect(() => {
    if (history.location.state) {
      setCategoryFilter((prevState) => {
        return { [history.location.state]: !prevState[history.location.state] };
      });
    }
  }, [history.location.state]);

  const showFilteredProducts = () => {
    let FilteredProductsName = [];
    for (const [key, value] of Object.entries(categoryFilter)) {
      !!value && FilteredProductsName.push(key);
    }

    let filteredProducts = [];

    for (let i = 0; i < FilteredProductsName.length; i++) {
      for (let j = 0; j < products.length; j++) {
        if (
          products[j].categories[0].name === FilteredProductsName[i] ||
          products[j].categories[1]?.name === FilteredProductsName[i]
        ) {
          filteredProducts.push(
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              key={products[j].id}
              className={'p1'}
            >
              <ProductCardBox product={products[j]} />
            </Grid>
          );
        }
      }
    }

    filteredProducts.sort(function (a, b) {
      var titleA = a?.props?.children?.props?.product.title.toUpperCase(); // ignore upper and lowercase
      var titleB = b?.props?.children?.props?.product.title.toUpperCase(); // ignore upper and lowercase
      if (
        titleA === 'HYDRO BOOST CREAM NORMALNA-SUVA' ||
        titleA === 'HYDRO BOOST CREAM KOMBINOVANA-MASNA'
      ) {
        return -1;
      }

      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }

      // titles must be equal
      return 0;
    });

    return filteredProducts;
  };

  //if true show selected product cards
  let categoryStateValue = Object.values(categoryFilter).some((value) => value);

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="Danger">{error}</MessageBox>
      ) : (
        <Container maxWidth="xl">
          <Grid container component="section" className={'p1'}>
            <Grid item xs={12} className={c.productBoxHeader} container>
              {categoryList.map((category) => {
                return (
                  <Box p={1} key={category.id}>
                    <HashTag
                      onClick={() =>
                        setCategoryFilter({
                          ...categoryFilter,
                          [category.name]: !categoryFilter[category.name],
                        })
                      }
                      active={categoryFilter[category.name]}
                    >
                      #{category.name}
                    </HashTag>
                  </Box>
                );
              })}
            </Grid>
            {!categoryStateValue ? (
              <>
                {products.map((product, index) => {
                  products.sort(function (a, b) {
                    var titleA = a.title.toUpperCase(); // ignore upper and lowercase
                    var titleB = b.title.toUpperCase(); // ignore upper and lowercase

                    if (
                      titleA === 'HYDRO BOOST CREAM NORMALNA-SUVA' ||
                      titleA === 'HYDRO BOOST CREAM KOMBINOVANA-MASNA'
                    ) {
                      return -1;
                    }

                    if (titleA < titleB) {
                      return -1;
                    }
                    if (titleA > titleB) {
                      return 1;
                    }

                    // titles must be equal
                    return 0;
                  });

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                      key={product.id + index}
                      className={'p1'}
                    >
                      <ProductCardBox product={product} />
                    </Grid>
                  );
                })}
              </>
            ) : (
              showFilteredProducts()
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  productBoxHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default AllProductsPage;
