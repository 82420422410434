import React from 'react';
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { ContactForm } from 'containers/ContactPage';
import classNames from 'classnames';

const ContactPage = () => {
    const c = useStyles();
    const iframe = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2868.9632792040184!2d20.9058928157317!3d44.02215333651161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4757213103c2fe3f%3A0x3b82279bb7fde0e9!2sPri%C5%A1tinska%2011%2C%20Kragujevac!5e0!3m2!1sen!2srs!4v1607894577904!5m2!1sen!2srs" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;
    const showIframe = () => {
        return {
            __html: iframe
        }
    }

    return (
        <Container maxWidth="xl" className="padding-spacing-2">
            <Typography className="title textColor text-align-center lg-mg-bottom" variant="h1">
                Kontakt
            </Typography>
            <Grid
                container
                justify="space-around"
            >
                <Grid item xs={12} md={12}>
                    <Typography variant="body2" gutterBottom={true} className={classNames("title", c.contactInfo)}>
                        NAJBOLJE IZ PRIRODE DOO
                    </Typography>

                    <Typography variant="body2" gutterBottom={true} className={classNames("title", c.contactInfo)}>
                        Prištinska 11a, Kragujevac
                    </Typography>

                    <Typography variant="body2" gutterBottom={true} className={classNames("title", c.contactInfo)}>
                        +381 64 340 50 76
                    </Typography>

                    <Typography variant="body2" gutterBottom={true} className={classNames("title", c.contactInfo)}>
                        E-mail: kontakt@plemenitaulja.rs
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} className="text-align-center">
                    <Box p={1} dangerouslySetInnerHTML={showIframe()} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ContactForm />
                </Grid>
            </Grid>
        </Container >
    )
}

const useStyles = makeStyles((theme) => ({
    contactInfo: {
        paddingLeft: 8
    }
}))

export default ContactPage;