import { GET_HP_TESTIMONIALS_REQUEST, GET_HP_TESTIMONIALS_SUCCESS, GET_HP_TESTIMONIALS_FAIL } from 'shared/constants/hpTestimonialsConstant';

export const hpTestimonialsReducer = (state = { loading: false, hpTestimonials: null }, action) => {
    switch (action.type) {
        case GET_HP_TESTIMONIALS_REQUEST:
            return { loading: true }
        case GET_HP_TESTIMONIALS_SUCCESS:
            return { loading: false, hpTestimonials: action.payload }
        case GET_HP_TESTIMONIALS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}