import React, { useEffect } from 'react'
import { makeStyles, Box, Container } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import ArticlePageHeader from './ArticlePageHeader/ArticlePageHeader'
import UljeJezgraKajsije from 'shared/assets/img/ulje-jezgra-kajsije.jpg'
import { TipSection, LoadingBox, MessageBox } from 'components'
// import TopComments from './TopComments/TopComments';
import { getDetailsArticle } from 'libs/redux/actions/articleActions'
import { qaLong } from 'shared/assets/texts/qa'

const ArticlePage = (props) => {
  const c = useStyles()
  const dispatch = useDispatch()
  const articleDetails = useSelector((state) => state.articleDetails)
  const { loading, error, article } = articleDetails

  const slug = props.match.params.slug

  useEffect(() => {
    dispatch(getDetailsArticle(slug))
  }, [dispatch, slug])

  return (
    <>
      {loading ? (
        <LoadingBox className={c.loader} />
      ) : error ? (
        <MessageBox variant="Danger">{error}</MessageBox>
      ) : (
        <>
          <Container maxWidth="xl" className="padding-spacing">
            <Box component="article">
              <ArticlePageHeader article={article} />
              <TipSection
                imgPosition={'right'}
                image={UljeJezgraKajsije}
                qa={qaLong[3]}
              />
              {/* <TopComments /> */}
            </Box>
          </Container>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
}))

export default ArticlePage
