import findUserIpAddress from './findUserIpAddress';

export default async function createInitiateCheckoutData(eventId) {
  const userIp = await findUserIpAddress();

  return {
    event_name: 'InitiateCheckout',
    event_id: eventId,
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    event_source_url: window.location.href,
    user_data: {
      client_user_agent: navigator.userAgent,
      client_ip_address: userIp || '0.0.0.0',
    },
    custom_data: {
      currency: 'RSD',
    },
  };
}
