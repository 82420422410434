import React from 'react'
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const ContactUsSection = () => {
  const c = useStyles()
  return (
    <Container
      maxWidth="xl"
      className="lg-mg-bottom, padding-spacing textColor"
    >
      <Grid
        container
        justify="space-between"
        className="padding-spacing bkg-cover m1"
      >
        <Grid item xs={12} md={6} lg={8}>
          <Typography
            variant="h2"
            gutterBottom={true}
            className="text-align-center title"
            color="primary"
          >
            Pošaljite nam komentar ili pitanje u vezi sa našim proizvodima
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={4} className="text-align-end">
          <Link to="/kontakt" className={c.btnAddToCartLink}>
            <Button className={c.btnAddToCart} variant="outlined">
              Pošalji
            </Button>
          </Link>

          <Link to="/proizvodi" className={c.btnAddToCartLink}>
            <Button className={c.btnAddToCart} variant="outlined">
              Idi na shop
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  btnAddToCartLink: {
    width: '100%',
    display: 'inline',
  },
  btnAddToCart: {
    minWidth: 200,
    fontSize: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: 18,
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    paddingLeft: 50,
    paddingRight: 50,
    '& path': {
      stroke: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
  },
}))

export default ContactUsSection
