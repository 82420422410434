import React from 'react';
import { ArrowForwardIos } from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';

import theme from 'libs/theme/theme';

const NextArrow = ({ className, onClick }) => {
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ArrowForwardIos color={"primary"} style={{ fontSize: 60, opacity: isSmDown ? 0.3 : 1 }} className={className} onClick={onClick} />
    );
}
export default NextArrow;