import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { LoadingBox, MessageBox } from 'components';
import { sendContactFormData } from 'libs/redux/actions/contactFormActions';

const ContactForm = () => {
    const c = useStyles();
    const dispatch = useDispatch();
    const contactForm = useSelector((state) => state.contactForm);
    const { loading, error, msg } = contactForm;

    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        message: yup.string().required(),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <div className={c.paper}>
            <Grid container alignItems="center" justify="center">
                <form className={c.form} noValidate onSubmit={handleSubmit((data) => {
                    dispatch(sendContactFormData(data))
                })}>
                    <Grid item className={'mb1'} xs={12}>
                        <label className={c.ordersLabel} htmlFor="name">Ime:
                                    <input className={c.orderInputs} id="name" name="name" ref={register({ required: true })} type="text" />
                            {errors.name && <span className={c.inputError}>Potrebno je uneti vaše ime.</span>}
                        </label>
                    </Grid>

                    <Grid item className={'mb1'} xs={12}>
                        <label className={c.ordersLabel} htmlFor="email">Email:
                                    <input className={c.orderInputs} id="email" name="email" ref={register({ required: true })} type="email" />
                            {errors.email && <span className={c.inputError}>Potrebno je uneti vaš email.</span>}
                        </label>
                    </Grid>

                    <Grid item className={'mb1'} xs={12}>
                        <label className={c.ordersLabel} htmlFor="message">Vaša poruka:
                                    <textarea id="message" name="message" className={classNames(c.orderInputs, c.ordersTextarea)} rows={11} ref={register({ required: true })} />
                            {errors.message && <span className={c.inputError}>Potrebno je uneti poruku.</span>}
                        </label>
                    </Grid>
                    {
                        error ? (
                            <MessageBox variant="Danger">{error}</MessageBox>
                        ) : (
                                <Button
                                    disabled={msg ? true : false}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={c.submit}
                                    style={{ backgroundColor: msg && '#e35683', color: msg && '#fff', opacity: msg && 0.8 }}
                                    endIcon={loading && <LoadingBox size={24} color={'inherit'} className={c.buttonProgress} />}
                                >
                                    {msg ? msg : 'Pošalji'}
                                </Button>
                            )
                    }
                </form>
            </Grid>
        </div >
    );
}

const useStyles = makeStyles((theme) => ({
    mamutijebem: {
        backgroundColor: 'red',
        color: 'white'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    ordersLabel: {
        color: theme.palette.common.text,
        paddingTop: theme.spacing(1)
    },
    orderInputs: {
        width: '100%',
        borderRadius: 10,
        borderColor: theme.palette.primary.main,
        padding: 5,
    },
    inputError: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        fontSize: 14
    },
    ordersTextarea: {
        resize: 'none',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export default ContactForm;