import React from 'react'
import { Grid, Typography, makeStyles, Container } from '@material-ui/core'
import classNames from 'classnames'

const LearnAboutProduct = ({ learn_all_about_this_product }) => {
  const c = useStyles()

  const showOther = (learn_all_about_this_product) => {
    const newContent = learn_all_about_this_product.replace(
      '/files',
      'https://starfish-app-vtnjb.ondigitalocean.app/files',
    )
    return {
      __html: newContent,
    }
  }
  return (
    <Container maxWidth="xl" className="padding-spacing">
      <Grid
        container
        component="section"
        className="bkg-cover textColor padding-spacing-2 m1"
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            className={classNames('text-align-center', 'title')}
            gutterBottom={true}
            color="primary"
          >
            Saznajte sve o ovom proizvodu
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={c.textAbout}
            variant="body1"
            dangerouslySetInnerHTML={showOther(learn_all_about_this_product)}
            gutterBottom={true}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  textAbout: {
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'start',
      fontSize: theme.spacing(2),
    },
  },
}))

export default LearnAboutProduct
