export const DotPrice = (price) => {
    let stringedPrice;
    if (price) {
        stringedPrice = price.toString()
    } else {
        return null
    }

    let newPrice = '';
    if (stringedPrice.length > 3) {
        stringedPrice.split("").forEach((num, index) => {
            if (index !== stringedPrice.length - 4) {
                newPrice += num;
            } else {
                newPrice += num;
                newPrice += '.';
            }
        });
        return newPrice;
    }
    return price;
}