import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { QAcard } from 'components';

const QAsection = ({ qa }) => {
    const c = useStyles();
    let QAtitle = 'Q&A Segment';

    return (
        <Box >
            <Typography
                variant="h2"
                className={classNames(c.QAtitle, "text-align-center")}
                color="primary"
            >
                {QAtitle}
            </Typography>
            <Grid container className="padding-spacing-2">
                {
                    qa.map((item, index) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={3}
                                className={c.QAcardWrapper}
                                key={index + 1}
                            >
                                <QAcard qa={item} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    QAtitle: {
        fontWeight: 700
    },
    QAcardWrapper: {
        padding: 5,
        display: 'flex',
    }
}))

export default QAsection;