import React from 'react'
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  useMediaQuery,
} from '@material-ui/core'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import { HashTag } from 'components'
import theme from 'libs/theme/theme'

const ArticlePageHeader = ({ article }) => {
  const { title, content, hash } = article
  const c = useStyles()
  const history = useHistory()
  const showOther = () => {
    const newContent = content.replace(
      '/files',
      'https://starfish-app-vtnjb.ondigitalocean.app/files',
    )
    return {
      __html: newContent,
    }
  }

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box component="article" pb={4}>
      {isSmDown ? (
        <>
          <Box p={1}>
            <HashTag onClick={() => history.push('/blog', hash)} active>
              #{hash}
            </HashTag>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="h1"
              className={c.articleHeaderTitle}
              gutterBottom={true}
            >
              {title}
            </Typography>
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="h1"
            className={c.articleHeaderTitle}
            gutterBottom={true}
          >
            {title}
          </Typography>

          <Box p={1}>
            <HashTag onClick={() => history.push('/blog', hash)} active>
              #{hash}
            </HashTag>
          </Box>
        </Box>
      )}

      <Grid container justify="space-between">
        <Grid
          item
          xs={12}
          lg={12}
          className={classNames('p1', c.articleHeaderTextWrapper)}
        >
          <Box>
            <Box
              className={classNames(
                c.articleHeaderText,
                'padding-spacing-2',
                'textColor',
              )}
              dangerouslySetInnerHTML={showOther()}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  articleHeaderTitle: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  articleHeaderTextWrapper: {
    borderRadius: '30px',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  articleHeaderText: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '30px',
  },
}))

export default ArticlePageHeader
