import axios from 'axios';
import axiosUrl from 'libs/axiosUrl';
import { GET_PROMOTIONS_REQUEST, GET_PROMOTIONS_SUCCESS, GET_PROMOTIONS_FAIL } from 'shared/constants/promotionConstant';

export const getPromotions = () => async (dispatch) => {
    dispatch({ type: GET_PROMOTIONS_REQUEST })

    try {
        const res = await axios.get(`${axiosUrl}/promotions`);
        dispatch({ type: GET_PROMOTIONS_SUCCESS, payload: res.data })
    } catch (error) {
        dispatch({ type: GET_PROMOTIONS_FAIL, payload: error.message })
    }
}