import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const LoadingBox = ({ className = false, size, color }) => {
    const c = useStyles();
    return (
        <Box className={className ? className : c.loader}>
            <CircularProgress size={size} color={color} />
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
    }
}));

export default LoadingBox;