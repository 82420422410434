import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import classNames from 'classnames';

const SocialIcon = ({ logo, href, fontSize = false }) => {
    const [isShown, setIsShown] = useState(false);
    const c = useStyles(fontSize);

    const whichLogo = {
        facebook: () => <FacebookIcon className={classNames(c.icon)} />,
        instagram: () => <InstagramIcon className={classNames(c.icon)} />,
        // youtube: () => <YouTubeIcon className={classNames(c.icon)} />
    }

    const Logo = whichLogo[logo];

    return (
        <a
            href={href}
            style={{ padding: 1 }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            className={isShown ? "animate__animated animate__pulse" : ""}
        >
            <Logo />
        </a>
    )
}

const useStyles = makeStyles((theme) => ({
    icon: {
        [theme.breakpoints.up('xs')]: {
            fontSize: (fontSize) => fontSize ? fontSize : 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: (fontSize) => fontSize ? fontSize : 28,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: (fontSize) => fontSize ? fontSize : 32,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: (fontSize) => fontSize ? fontSize : 36,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: (fontSize) => fontSize ? fontSize : 40,
        },
    },
}));

export default SocialIcon;