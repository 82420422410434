import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core'

const PrivacyProtection = () => {
  return (
    <Container
      maxWidth="lg"
      className="lg-mg-bottom, padding-spacing textColor "
    >
      <Grid container className="bkg-cover mb4">
        <Grid item xs={12} className={'padding-spacing-2'}>
          <Typography variant="h2" gutterBottom={true} className="title">
            Zaštita privatnosti
          </Typography>

          <Typography gutterBottom={true}>
            U ime{' '}
            <span className="title">
              Najbolje iz prirode DOO Prištinska 11A Kragujevac
            </span>{' '}
            obavezujemo se da ćemo čuvati privatnost svih naših kupaca.
            Prikupljamo samo neophodne, osnovne podatke o kupcima/ korisnicima i
            podatke neophodne za poslovanje i informisanje korisnika u skladu sa
            dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Svi
            podaci o korisnicima/kupcima se strogo čuvaju i dostupni su samo
            zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi
            zaposleni u{' '}
            <span className="title">
              Najbolje iz prirode DOO Prištinska 11A Kragujevac
            </span>{' '}
            (i poslovni partneri) odgovorni su za poštovanje načela zaštite
            privatnosti.
          </Typography>

          <Typography gutterBottom={true}>
            U slučaju promene bilo kog ličnog podataka (npr. mesto stanovanja,
            adresa dostave) koji se evidentiraju prilikom registracije, Kupac je
            dužan o promeni obavestiti{' '}
            <span className="title">
              Najbolje iz prirode DOO Prištinska 11A Kragujevac.
            </span>{' '}
            U slučaju promene o kojoj nije obavešten,{' '}
            <span className="title">
              Najbolje iz prirode DOO Prištinska 11A Kragujevac
            </span>{' '}
            ne odgovara za bilo kakve nedostatke u pogledu narudžbine odnosno
            isporuke proizvoda.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PrivacyProtection
