import React from 'react';
import {
    Container,
    //  makeStyles 
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import BlogAndTipsHeader from './BlogAndTipsHeader/BlogAndTipsHeader';
import QAsection from './QAsection/QAsection';
import { TipSection } from 'components';
import { LoadingBox, MessageBox } from 'components';
import { qaLong, qaShort } from 'shared/assets/texts/qa';

import UljeDivljeRuze from 'shared/assets/img/ulje-divlje-ruze.jpg'
import UljeIMelemMix from 'shared/assets/img/ulje-i-melem-mix.jpg'

const BlogAndTipsPage = () => {
    const articleList = useSelector((state) => state.articleList);
    const { loading, error, articles } = articleList;

    return (
        <Container maxWidth="xl">
            {
                loading ? (
                    <LoadingBox />
                ) : error ? (
                    <MessageBox variant="Danger">{error}</MessageBox>
                ) : (
                    <Container maxWidth="xl" className="padding-spacing-2">
                        <BlogAndTipsHeader articles={articles} />
                        <TipSection imgPosition={'left'} qa={qaLong[0]} />
                        <TipSection imgPosition={'right'} qa={qaLong[1]} image={UljeDivljeRuze} />
                        <TipSection imgPosition={'left'} qa={qaLong[2]} image={UljeIMelemMix} />
                        <QAsection qa={qaShort} />
                    </Container>
                )
            }
        </Container>
    )
}

export default BlogAndTipsPage;