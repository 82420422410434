import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core'

const DeliveryAndPayment = () => {
  return (
    <Container
      maxWidth="lg"
      className="lg-mg-bottom, padding-spacing textColor "
    >
      <Grid container className="bkg-cover mb4">
        <Grid item xs={12} className={'padding-spacing-2'}>
          <Typography variant="h2" gutterBottom={true} className="title">
            Dostava i plaćanje
          </Typography>

          <Typography gutterBottom={true}>
            Plaćanje je moguće pouzećem ili pre slanja uplatom na račun.
          </Typography>

          <Typography gutterBottom={true}>
            Podaci za uplatu na račun su 170-0030036846000-57
            <br />
            <span className="title">
              Najbolje iz prirode DOO Prištinska 11A Kragujevac.
            </span>
          </Typography>

          <Typography gutterBottom={true}>
            Za uplatu na račun najbolje je kontaktirati preko društvenih mreža
            ili mejla da bi se spojila porudžbina sa dokazom o uplati.
          </Typography>

          <Typography gutterBottom={true}>
            {' '}
            Isporuke na teritoriji Srbije se vrše u saradnji sa kuririskom
            službom.{' '}
            <span className="title">
              Cena isporuke se naknadno naplaćuje prilikom preuzimanja pošiljke.
            </span>
          </Typography>

          <Typography gutterBottom={true}>
            Rok isporuke je 2 do 5 radnih dana.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default DeliveryAndPayment
