import React from 'react';
import {
    Box, Typography,
    // Avatar,
    makeStyles
} from '@material-ui/core';

import Logo from 'shared/assets/img/plemenita-ulja-logo-roze.png'
import classNames from 'classnames';

const QAcard = ({ qa }) => {
    const c = useStyles();

    return (
        <Box className={classNames(c.cardWrapper, "bkg-cover textColor text-align-center")} p={2}>
            <Box display={'inline-block'} textAlign='center' mb={1}>
                <img style={{ width: '80%', height: '80%' }} src={Logo} alt="plemenita ulja" />
                {/* <Avatar alt="description" src={Logo} style={{ width: 75, height: 75 }} /> */}
            </Box>
            <Typography variant="h4" gutterBottom={true} className={classNames(c.QAWrapper, "text-align-center")}>
                {qa.title}
            </Typography>

            <Typography variant="body2" gutterBottom={true}>
                {qa.text}
            </Typography>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        margin: '0 auto',
        maxWidth: 300,
        minWidth: 220,
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    QAWrapper: {
        fontWeight: 600,
        marginBottom: 10
    }
}))

export default QAcard;