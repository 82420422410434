import Axios from "axios";
import axiosUrl from "libs/axiosUrl";

import { CONTACT_FORM_FAIL, CONTACT_FORM_REQUEST, CONTACT_FORM_SUCCESS } from "shared/constants/contactConstant";

export const sendContactFormData = (formData) => async (dispatch) => {
    dispatch({ type: CONTACT_FORM_REQUEST })

    try {
        await Axios.post(`${axiosUrl}/contact-mails`, formData);
        dispatch({ type: CONTACT_FORM_SUCCESS, payload: "Uspešno ste poslali poruku" })
    } catch (error) {
        dispatch({ type: CONTACT_FORM_FAIL, payload: "Došlo je do greške, pokušajte ponovo" })
    }
}