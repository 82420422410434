import React from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { BlogCard } from 'components'
import { Link } from 'react-router-dom'
import GoldHydroBoostPhoto from 'shared/assets/img/gold-hydro-boost-07.jpg'
import AntiridPhoto from 'shared/assets/img/antirid-01.jpg'
import ZlatniSerumPhoto from 'shared/assets/img/zlatni-serum-01.jpg'

const SpecialOffer = () => {
  const c = useStyles()

  return (
    <Container maxWidth="xl" className="padding-spacing" component="section">
      <Typography
        variant="h2"
        className={classNames('text-align-center', 'title')}
        gutterBottom={true}
        color="primary"
      >
        Izdvajamo iz ponude
      </Typography>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Link to={'/proizvod/gold-hydro-boost'} className={c.cardLink}>
            <BlogCard
              postTitle={'Gold Hydro Boost'}
              thumbnail_image={GoldHydroBoostPhoto}
              specialOffer
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={'/proizvod/antirid'} className={c.cardLink}>
            <BlogCard
              postTitle={'Antirid'}
              thumbnail_image={AntiridPhoto}
              specialOffer
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={'/proizvod/zlatni-serum'} className={c.cardLink}>
            <BlogCard
              postTitle={'Zlatni serum'}
              thumbnail_image={ZlatniSerumPhoto}
              specialOffer
            />
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  cardLink: {
    display: 'flex',
    flex: 1,
    '&:hover': {
      opacity: 1,
      '& h4': {
        color: theme.palette.primary.main,
        transition: 'color 0.5s',
      },
    },
  },
}))

export default SpecialOffer
