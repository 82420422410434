import React from 'react';
import { makeStyles, Grid, Typography, ListItem, useMediaQuery } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { addToCart, removeFromCart } from 'libs/redux/actions/cartActions';
import { useDispatch } from 'react-redux';

import { DotPrice } from 'shared/functions/DotPrice.js';
import theme from 'libs/theme/theme';

const ProductCart = ({ product }) => {
    const { productId, productName, price, qty, size, categoryName, slug } = product;
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const c = useStyles();
    let dispatch = useDispatch();
    let history = useHistory();

    let isPromotion = categoryName === "Promocije" ? true : false;

    const removeFromCartHandler = ({ productId, size }) => {
        dispatch(removeFromCart(productId, size))
        history.push('/korpa');
    }

    const qtyPrice = qty * price;
    const newDottedPrice = DotPrice(qtyPrice);
    const dottedPrice = DotPrice(price);

    return (
        <ListItem>
            <Grid container justify="center" className={'bkg-cover'}>
                <Grid item xs={6} sm={1} className={classNames(c.iconWrapper, 'text-align-center')}>
                    <ClearIcon onClick={() => removeFromCartHandler({ productId, size })} />
                </Grid>

                <Grid item xs={6} sm={2} className={classNames(c.imgWrapper)}>
                    <Link to={
                        isPromotion ?
                            `/promocija/${slug}` :
                            `/proizvod/${slug}`
                    }>
                        <img
                            src={product.image}
                            alt={'description'}
                        />
                    </Link>
                </Grid>

                <Grid item xs={12} sm={5} className={isMd ? "text-align-center" : "inherit"}>
                    <Link to={
                        isPromotion ?
                            `/promocija/${slug}` :
                            `/proizvod/${slug}`
                    }>
                        <Typography className={"title"} >
                            {productName} {size}
                        </Typography>
                    </Link>
                    <Typography className={c.productPriceNumber}>
                        {dottedPrice},00 din
                    </Typography>
                </Grid>

                <Grid item xs={6} sm={2} className={isXs ? "text-align-center" : "inherit"}>
                    <Typography className={"title"} gutterBottom={true}>
                        Kom
                    </Typography>

                    <select
                        value={qty}
                        className={c.selectQty}
                        onChange={(e) =>
                            dispatch(
                                addToCart(
                                    productId,
                                    Number(e.target.value),
                                    { price: price, size: size, isPromotion: isPromotion, slug: slug }
                                )
                            )}
                    >
                        {
                            [...Array(20).keys()].map(
                                (x) => (
                                    <option key={x + 1} value={x + 1}>{x + 1}</option>
                                )
                            )
                        }
                    </select>
                </Grid>

                <Grid item xs={6} sm={2} className={classNames("pb1", isXs ? "text-align-center" : "inherit")}>
                    <Typography className={"title"} gutterBottom={true}>
                        Ukupno
                    </Typography>

                    <Typography>{newDottedPrice},00 din</Typography>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    iconWrapper: {
        padding: theme.spacing(2),
        color: theme.palette.primary.main,
        "& svg:hover": {
            cursor: 'pointer'
        }
    },
    imgWrapper: {
        padding: theme.spacing(1),
        "& img": {
            maxHeight: '100px',
            maxWidth: '100px',
        }
    },
    productPriceNumber: {
        color: theme.palette.common.green,
        fontWeight: 700,
        fontSize: theme.spacing(4)
    },
    selectQty: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        width: 50,
    },

}));

export default ProductCart;