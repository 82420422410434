import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { LoadingBox, MessageBox, Testimonials } from 'components';
import { ProductHeader } from '../ProductPage';
import { getDetailsProduct } from 'libs/redux/actions/productActions';
import CtaSection from './CtaSection/CtaSection';
import { ContactUsSection } from '../HomePage';

const ProductPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const c = useStyles();

  const productSlug = props.match.params.slug;
  const productDetails = useSelector((state) => state.productDetails);
  const { error, loading, product } = productDetails;
  const [whichHashIsActive, setWhichHashIsActive] = useState(true);
  const [qty, setQty] = useState(1);

  useEffect(() => {
    dispatch(getDetailsProduct(productSlug));
  }, [dispatch, productSlug]);

  const addToCartHandler = useCallback(
    (e, stateObj, id) => {
      history.push(`/korpa/${id}?qty=${qty}`, stateObj);
    },
    [history, qty]
  );

  const activeHashToTrue = () => setWhichHashIsActive(true);

  const activeHashToFalse = () => setWhichHashIsActive(false);

  return (
    <>
      {loading ? (
        <LoadingBox className={c.loader} />
      ) : error ? (
        <MessageBox variant="Danger">{error}</MessageBox>
      ) : (
        <>
          <ProductHeader
            product={product[0]}
            whichHashIsActive={whichHashIsActive}
            qty={qty}
            setQty={setQty}
            addToCartHandler={addToCartHandler}
            activeHashToTrue={activeHashToTrue}
            activeHashToFalse={activeHashToFalse}
          />

          {/* {product.length > 0 && product[0].benefit_content_1 && (
            <Benefits benefits={product[0]} />
          )} */}

          {product[0]?.testimonials_gallery &&
            product[0]?.testimonials_gallery.length > 0 && (
              <Testimonials
                testimonials_gallery={product[0].testimonials_gallery}
                product={product}
              />
            )}

          {/* {product.length > 0 &&
            !(product[0].title === 'Balzam za kosu') &&
            !(product[0].title === 'Piling za telo') &&
            !(product[0].title === 'Čvrsti balzam za kosu') &&
            !(product[0].title === 'Šampon') &&
            !(product[0].title === 'Gold hydro boost krema za lice') &&
            product[0].learn_all_about_this_product &&
            product[0].slug !== 'deo-stik-citrus' &&
            product[0].slug !== 'deo-stik-fresh' && (
              <LearnAboutProduct
                learn_all_about_this_product={
                  product[0].learn_all_about_this_product
                }
              />
            )} */}

          <ContactUsSection />

          {product.length > 0 &&
            !(product[0].title === 'Balzam za kosu') &&
            !(product[0].title === 'Piling za telo') &&
            !(product[0].title === 'Čvrsti balzam za kosu') &&
            !(product[0].title === 'Šampon') &&
            !(product[0].title === 'Gold hydro boost krema za lice') && (
              <Container
                maxWidth="xl"
                className="padding-spacing"
                style={{ textAlign: '-webkit-right' }}
              >
                <CtaSection
                  product={product[0]}
                  whichHashIsActive={whichHashIsActive}
                  qty={qty}
                  setQty={setQty}
                  addToCartHandler={addToCartHandler}
                  activeHashToTrue={activeHashToTrue}
                  activeHashToFalse={activeHashToFalse}
                />
              </Container>
            )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
}));

export default ProductPage;
