import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Slide,
  Typography,
  AppBar,
  useScrollTrigger,
  Grid,
  makeStyles,
  Hidden,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';

import Logo from 'shared/assets/img/plemenita-ulja-logo-roze.png';
import { ReactComponent as ShoppingCardIcon } from 'shared/assets/svg/shopping-cart.svg';
import MobileDrawer from './MobileDrawer';

import { SocialIcon } from 'components';
import { useSelector } from 'react-redux';

const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const HideAppBar = (props) => {
  const c = useStyles();
  const categories = useSelector((state) => state.categories.categoryList);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  if (categories.length > 0) {
    // sort by name
    categories.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar position="sticky" color="secondary" ref={props.headerRef}>
          <Box className={c.upperHeaderWrapper}>
            <Container maxWidth="xl">
              <Grid
                container
                className={classNames('text-align-center', 'p1')}
                display="flex"
                justify="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={11}
                  sm={10}
                  md={10}
                  className={c.upperHeaderTextWrapper}
                >
                  <Typography
                    variant="body1"
                    gutterBottom={true}
                    className={c.upperHeaderText}
                  >
                    Najbolji odgovor prirode za vašu negu
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={2} md={2} className={c.socialIconWrapper}>
                  <SocialIcon
                    href={'https://www.facebook.com/uljedivljeruze'}
                    logo={'facebook'}
                  />
                  <SocialIcon
                    href={'https://www.instagram.com/plemenita_ulja/'}
                    logo={'instagram'}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Container maxWidth="xl">
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={'p1'}
            >
              {/* LOGO START */}
              <Grid item lg={2} className={c.logoWrapper}>
                <Link to="/">
                  <img className={c.logoImg} src={Logo} alt="plemenita ulja" />
                </Link>
              </Grid>
              {/* LOGO END */}

              {/* NAVBAR START */}
              <Grid item lg={10} className={c.menuWrapper}>
                <List className={c.mainMenu}>
                  <ListItem>
                    <Link to="/o-nama">
                      <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      >
                        O nama
                      </ListItemText>
                    </Link>
                  </ListItem>

                  <ListItem className={c.productsListItem}>
                    <Link to="/proizvodi">
                      <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      >
                        Proizvodi
                      </ListItemText>
                      <ArrowDropDownIcon />
                    </Link>

                    <List className={c.nestedList} id="sub-menu">
                      {categories.map((productCategory, index) => {
                        // sort by title
                        productCategory.product.sort(function (a, b) {
                          var titleA = a.title.toUpperCase(); // ignore upper and lowercase
                          var titleB = b.title.toUpperCase(); // ignore upper and lowercase

                          if (
                            titleA === 'HYDRO BOOST CREAM NORMALNA-SUVA' ||
                            titleA === 'HYDRO BOOST CREAM KOMBINOVANA-MASNA'
                          ) {
                            return -1;
                          }

                          if (titleA < titleB) {
                            return -1;
                          }
                          if (titleA > titleB) {
                            return 1;
                          }

                          // titles must be equal
                          return 0;
                        });
                        return (
                          <Box className={c.productLinkAlike} key={index}>
                            <ListItem>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { fontSize: 18 },
                                }}
                              >
                                {productCategory.name}
                              </ListItemText>
                            </ListItem>

                            <List
                              className={c.nestedListByCategory}
                              id="sub-nested-menu"
                            >
                              {productCategory.product.map((product) => {
                                return (
                                  <Link
                                    to={`/proizvod/${product.slug}`}
                                    key={product.id}
                                    className={c.productSubLink}
                                  >
                                    <ListItem>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          style: { fontSize: 18 },
                                        }}
                                      >
                                        {product.title}
                                      </ListItemText>
                                    </ListItem>
                                  </Link>
                                );
                              })}
                            </List>
                          </Box>
                        );
                      })}
                    </List>
                  </ListItem>

                  <ListItem>
                    <Link to="/blog-i-saveti">
                      <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      >
                        Saveti
                      </ListItemText>
                    </Link>
                  </ListItem>

                  <ListItem className={c.productsListItem}>
                    <Link to="">
                      <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      >
                        Dodatne informacije
                      </ListItemText>
                      <ArrowDropDownIcon />
                    </Link>

                    <List className={c.nestedList} id="sub-menu">
                      <List>
                        <Link
                          className={c.productSubLink}
                          to="/dostava-i-placanje"
                        >
                          <ListItem>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 18 },
                              }}
                            >
                              Dostava i plaćanje
                            </ListItemText>
                          </ListItem>
                        </Link>

                        <Link
                          className={c.productSubLink}
                          to="/reklamacija-i-povracaj-robe"
                        >
                          <ListItem>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 18 },
                              }}
                            >
                              Reklamacija i povraćaj robe
                            </ListItemText>
                          </ListItem>
                        </Link>

                        <Link
                          className={c.productSubLink}
                          to="/zastita-privatnosti"
                        >
                          <ListItem>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 18 },
                              }}
                            >
                              Zaštita privatnosti
                            </ListItemText>
                          </ListItem>
                        </Link>

                        <Link
                          className={c.productSubLink}
                          to="/prava-i-obaveze-potrosaca"
                        >
                          <ListItem>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 18 },
                              }}
                            >
                              Prava i obaveze potrošača
                            </ListItemText>
                          </ListItem>
                        </Link>
                      </List>
                    </List>
                  </ListItem>

                  <ListItem>
                    <Link to="/kontakt">
                      <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      >
                        Kontakt
                      </ListItemText>
                    </Link>
                  </ListItem>

                  <ListItem style={{ paddingRight: 0 }}>
                    <Link
                      to={{
                        pathname: `/korpa`,
                      }}
                    >
                      <ShoppingCardIcon className={c.shoppingCartIcon} />
                    </Link>
                    {cartItems.length > 0 && (
                      <Box component="span" className={c.badge}>
                        {cartItems.length}
                      </Box>
                    )}
                  </ListItem>
                </List>

                {/* HAMBURGER BTN START*/}
                <Hidden mdUp>
                  <Grid item lg={8} container justify="flex-end">
                    <MobileDrawer />
                  </Grid>
                </Hidden>
                {/* HAMBURGER BTN END*/}
              </Grid>
              {/* NAVBAR END */}
            </Grid>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  upperHeaderWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  upperHeaderTextWrapper: {
    flex: 1,
    fontStyle: 'italic',
  },
  upperHeaderText: {
    fontWeight: 700,
    [theme.breakpoints.up('xs')]: {
      letterSpacing: '2px',
      textAlign: 'center',
      fontSize: 18,
      paddingLeft: 2,
      paddingRight: 2,
    },
    [theme.breakpoints.up('md')]: {
      letterSpacing: '6px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '8px',
    },
  },
  socialIconWrapper: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'end',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'end',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImg: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 80,
      maxHeight: 80,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 120,
      maxHeight: 120,
    },
  },
  shoppingCartIcon: {
    paddingRight: 0,
    [theme.breakpoints.up('xs')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  menuButton: {
    '& svg': {
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(6),
      },
    },
  },
  menuWrapper: {
    color: theme.palette.primary.main,
  },
  mainMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& li': {
      [theme.breakpoints.up('sm')]: {
        padding: '0 7px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 16px',
      },
    },
  },
  productsListItem: {
    position: 'relative',
    '&:hover #sub-menu': {
      display: 'block',
    },
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    padding: '0.2rem 0.7rem',
    fontSize: '0.9rem',
    marginLeft: '0.2rem',
  },
  nestedList: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 35,
    width: 220,
    display: 'none',
    '& img': {
      width: '35px',
      height: '35px',
    },
    padding: 0,
    '& li': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  nestedListByCategory: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    width: 249,
    // right: -249, kad se vrate blogovi
    right: 220,
    display: 'none',
    padding: 0,
    '& li': {
      padding: 5,
    },
  },
  productLinkAlike: {
    position: 'relative',
    '& li': {
      padding: 5,
    },
    '&:hover #sub-nested-menu': {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      transition: `background-color 0.3s, color 0.3s`,
    },
  },
  productSubLink: {
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      transition: `background-color 0.3s, color 0.3s`,
    },
  },
  listItemText: {
    fontWeight: 700,
  },
}));

export default HideAppBar;
