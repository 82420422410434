import React, { useEffect } from 'react'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { BrowserRouter, useLocation, withRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import theme from 'libs/theme/theme'
import GlobalStyles from 'libs/globalStyles/GlobalStyles'
import { Main } from 'containers'
import store from 'libs/redux/store'

const LoggedOutComponent = Main

const App = () => {
  const _ScrollToTop = (props) => {
    const { pathname } = useLocation()
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
    return props.children
  }
  const ScrollToTop = withRouter(_ScrollToTop)

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <LoggedOutComponent />
          </MuiThemeProvider>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  )
}

export default App
