import Axios from 'axios';
import axiosUrl from 'libs/axiosUrl';
import { CART_ADD_ITEM, CART_REMOVE_ITEM } from 'shared/constants/cartConstant';

export const addToCart =
  (productId, qty, stateObj) => async (dispatch, getState) => {
    let url = stateObj.isPromotion
      ? `${axiosUrl}/promotions/${productId}`
      : `${axiosUrl}/products/${productId}`;

    const { data } = await Axios.get(url);

    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        categoryName: data.categories[0].name,
        productName: data.title,
        image:
          data.gallery.length > 0
            ? `${axiosUrl}${data.gallery[0].formats.small.url}`
            : '',
        price: stateObj.price,
        productId: productId,
        qty: qty,
        size: stateObj.size,
        slug: stateObj.slug,
      },
    });

    sessionStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const removeFromCart = (productId, size) => (dispatch, getState) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: { productId, size } });
  sessionStorage.setItem(
    'cartItems',
    JSON.stringify(getState().cart.cartItems)
  );
};
