import React from 'react'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import theme from 'libs/theme/theme'

const SocialIntegration = ({ instaFeed }) => {
  const c = useStyles()

  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: isXs ? 2 : isSm ? 4 : isMd ? 6 : 8,
    slidesToScroll: 1,
    centerMode: true,
  }

  if (!instaFeed.length) {
    return null
  }

  instaFeed.length = 35

  return (
    <Slider {...settings}>
      {instaFeed.map((item, index) => {
        const isVideo = item.media_url.match('video')
        if (isVideo) {
          return (
            <a
              href={item.permalink}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              className={c.singleSliderBox}
            >
              <img
                src={item.thumbnail_url}
                alt="instagram-post"
                className={c.logoImg}
              />
            </a>
          )
        }
        return null
      })}
    </Slider>
  )
}

const useStyles = makeStyles(() => ({
  singleSliderBox: {
    '&:hover': {
      opacity: 0.9,
    },
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
}))

export default SocialIntegration
