import React, {
  // useMemo,
  useEffect,
  // useState
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { DotPrice } from 'shared/functions/DotPrice';
import { orderProducts } from 'libs/redux/actions/orderActions';
import { LoadingBox } from 'components';

const OrderPage = () => {
  // const [newPriceFlag, setNewPriceFlag] = useState(false);
  const c = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const order = useSelector((state) => state.order);
  const { loading } = order;
  // const globalCoupon = process.env.REACT_APP_COUPON;
  // const globalCoupon2 = process.env.REACT_APP_COUPON2;
  const {
    register,
    handleSubmit,
    errors,
    // watch
  } = useForm();
  // const watchCoupon = watch('coupon', false) ?? '';

  let price = cartItems.reduce((a, c) => a + c.price * c.qty, 0);

  const minZaBesplatnuDostavu = 5000;
  // const minZaBesplatnuDostavu = newPriceFlag ? 3000 : 5000;
  const isFreeShipping = price > minZaBesplatnuDostavu;
  const cenaDostave = isFreeShipping ? '0 din' : '320,00 din';
  const finallPrice = isFreeShipping ? price : price + 320;

  const onSubmit = (data) => {
    data.cartItems = [];
    cartItems.forEach((item) => {
      data.cartItems.push({
        categoryName: item.categoryName,
        price: item.price,
        productName: item.size
          ? `${item.productName} ${item.size}`
          : item.productName,
        qty: item.qty,
      });
    });
    data.shippingPrice = data.shippingPrice = cenaDostave;
    data.finallPrice = finallPrice;
    // data.isValidCoupon = newPriceFlag;

    dispatch(orderProducts(data));
  };

  useEffect(() => {
    if (cartItems.length === 0) {
      history.push('/');
    }
  }, [cartItems, dispatch, history]);

  // const validate = useMemo(() => {
  //   return (input) => {
  //     return (
  //       typeof input === 'string' &&
  //       input.trim().toLowerCase().replace(/ +/g, '').replace('š', 's')
  //     );
  //   };
  // }, []);

  // useEffect(() => {
  //   const isValidInput = validate(watchCoupon);

  //   if (isValidInput === globalCoupon || isValidInput === globalCoupon2) {
  //     setNewPriceFlag(true);
  //   } else {
  //     setNewPriceFlag(false);
  //   }
  // }, [watchCoupon, globalCoupon, globalCoupon2, setNewPriceFlag, validate]);

  return (
    <Container maxWidth="xl" className="padding-section">
      <Box component="section" className="padding-section-2">
        <Typography
          variant="h2"
          gutterBottom={true}
          className={classNames('title', 'text-align-center', 'textColor')}
        >
          Unesite podatke za dostavu
        </Typography>

        <Grid container>
          <Grid item xs={12} md={6} className={'p1'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="email">
                  E-Mail:
                  <input
                    className={c.orderInputs}
                    id="email"
                    name="email"
                    ref={register({ required: true })}
                    type="email"
                  />
                  {errors.email && (
                    <span className={c.inputError}>
                      Potrebno je uneti email adresu.
                    </span>
                  )}
                </label>
              </Grid>

              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="name">
                  Ime i prezime:
                  <input
                    className={c.orderInputs}
                    id="name"
                    name="name"
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.name && (
                    <span className={c.inputError}>
                      Potrebno je uneti ime i prezime.
                    </span>
                  )}
                </label>
              </Grid>

              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="address">
                  Adresa:
                  <input
                    className={c.orderInputs}
                    id="address"
                    name="address"
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.address && (
                    <span className={c.inputError}>
                      Potrebno je uneti adresu.
                    </span>
                  )}
                </label>
              </Grid>

              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="postCode">
                  Poštanski broj:
                  <input
                    className={c.orderInputs}
                    id="postCode"
                    name="postCode"
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.postCode && (
                    <span className={c.inputError}>
                      Potrebno je uneti poštanski broj.
                    </span>
                  )}
                </label>
              </Grid>

              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="city">
                  Grad:
                  <input
                    className={c.orderInputs}
                    id="city"
                    name="city"
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.city && (
                    <span className={c.inputError}>
                      Potrebno je uneti grad.
                    </span>
                  )}
                </label>
              </Grid>

              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="phone">
                  Kontakt telefon:
                  <input
                    className={c.orderInputs}
                    id="phone"
                    name="phone"
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.phone && (
                    <span className={c.inputError}>
                      Potrebno je uneti broj telefona.
                    </span>
                  )}
                </label>
              </Grid>

              {/* <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel} htmlFor="coupon">
                  Kupon:
                  <input
                    className={c.orderInputs}
                    id="coupon"
                    name="coupon"
                    ref={register({
                      validate: (value) =>
                        validate(value) === globalCoupon ||
                        validate(value) === globalCoupon2 ||
                        value === '',
                    })}
                    type="text"
                  />
                  {errors.coupon && (
                    <span className={c.inputError}>
                      Potrebno je uneti validan kupon ukoliko ga imate.
                    </span>
                  )}
                </label>
              </Grid> */}

              <Grid item className={'mb1'} xs={12}>
                <label className={c.ordersLabel}>
                  Napomena:
                  <textarea
                    className={classNames(c.orderInputs, c.ordersTextarea)}
                    rows={5}
                    name="textarea"
                    ref={register}
                  />
                </label>
              </Grid>

              <Grid item className={'mb1'} xs={12}>
                <Button
                  children={<input type="submit" value="Poruči" />}
                  type="submit"
                  className={classNames(c.orderBtn, 'btn-contained')}
                  variant="outlined"
                  endIcon={
                    loading && (
                      <LoadingBox
                        size={24}
                        color={'inherit'}
                        className={c.buttonProgress}
                      />
                    )
                  }
                >
                  Poruči
                </Button>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12} md={6} className={classNames('p1')}>
            <Typography>Porudžbina:</Typography>

            <Box className={classNames(c.orderBox)}>
              {/* HEADER */}
              <Grid container className={c.orderHeaderBox}>
                <Grid item xs={3}>
                  <Typography>Proizvod</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography>Cena</Typography>
                </Grid>

                <Grid item xs={2} md={3}>
                  <Typography>Kom</Typography>
                </Grid>

                <Grid item xs={4} md={3}>
                  <Typography>Ukupno</Typography>
                </Grid>
              </Grid>

              {/* BODY */}
              {cartItems.map((article, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item xs={3}>
                      <Typography>{article.productName}</Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography>
                        {DotPrice(article.price)}
                        <small>,00 din</small>
                      </Typography>
                    </Grid>

                    <Grid item xs={2} md={3}>
                      <Typography>{article.qty}</Typography>
                    </Grid>

                    <Grid item xs={4} md={3}>
                      <Typography>
                        {DotPrice(article.price * article.qty)}
                        <small>,00 din</small>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid container className={c.orderSum}>
                <Grid item xs={7} sm={8} md={9}>
                  <Typography className={classNames('text-align-end', 'title')}>
                    Dostava:
                  </Typography>
                </Grid>

                <Grid item xs={5} sm={4} md={3}>
                  <Typography>{cenaDostave}</Typography>
                </Grid>
              </Grid>

              {/* UKUPNO */}
              <Grid container className={c.orderSum}>
                <Grid item xs={7} sm={8} md={9}>
                  <Typography className={classNames('text-align-end', 'title')}>
                    UKUPNO:
                  </Typography>
                </Grid>

                <Grid item xs={5} sm={4} md={3}>
                  <Typography className="title">
                    {DotPrice(finallPrice)}
                    ,00 din
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  ordersLabel: {
    color: theme.palette.common.text,
    paddingTop: theme.spacing(1),
  },
  orderInputs: {
    width: '100%',
    borderRadius: 10,
    borderColor: theme.palette.primary.main,
    padding: 5,
  },
  inputError: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontSize: 14,
  },
  orderBtn: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  ordersTextarea: {
    resize: 'none',
  },
  orderBox: {
    '& p': {
      [theme.breakpoints.up('xs')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
    },
    borderRadius: 10,
    '& > div:nth-of-type(2n+1)': {
      backgroundColor: '#ccc',
    },
    '& > div:nth-of-type(2n+0)': {
      backgroundColor: theme.palette.common.white,
    },
    '& div': {
      padding: 8,
    },
  },
  orderHeaderBox: {
    borderRadius: '15px 15px 0 0',
    '& p': {
      fontWeight: 500,
    },
  },
  orderSum: {
    '& p': {
      fontWeight: 500,
    },
  },
  orderFooterBox: {
    borderRadius: '0 0 15px 15px',
    '& p': {
      fontWeight: 200,
      fontSize: 14,
    },
  },
  buttonProgress: {
    display: 'flex',
    alignItems: 'center',
  },
  postExpressAnchor: {
    color: theme.palette.primary.main,
  },
}));

export default OrderPage;
