import React from 'react'
import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { DotPrice } from 'shared/functions/DotPrice.js'
import axiosUrl from 'libs/axiosUrl'
import theme from 'libs/theme/theme'

const ProductCardBox = ({ product }) => {
  const { main_content, price, title, gallery, slug } = product
  const c = useStyles()
  const dottedPrice = DotPrice(price)

  const showOther = (main_content) => {
    return {
      __html: main_content,
    }
  }

  let isPromotion = product.categories[0].name === 'Promocije' ? true : false
  let isHydro = product.categories[0].name === 'Hidrolati'
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Link
      className={c.productCardBoxImage}
      to={{
        pathname: isPromotion ? `/promocija/${slug}` : `/proizvod/${slug}`,
        state: { isPromotion: isPromotion ? true : false },
      }}
    >
      <Box
        className={c.productCardBoxWrapper}
        alignItems="center"
        height={'auto'}
        p={2}
      >
        <img
          alt="product"
          src={
            gallery.length > 0
              ? `${axiosUrl}${gallery[0].formats.medium.url}`
              : ''
          }
          className={c.productCardImage}
          style={{
            minHeight: isHydro ? (!isXs ? 237 : 'auto') : 'auto',
            maxHeight: isHydro ? (!isXs ? 237 : 'auto') : 'auto',
          }}
        />

        <Box>
          <Typography
            variant="body1"
            className={classNames('title', c.productCardBoxTitle)}
            color="primary"
          >
            {title}
          </Typography>

          {!(title === 'Piling za telo') &&
            !(title === 'Gold hydro boost krema za lice') && (
              <Typography className={c.productPrice}>
                {dottedPrice},00 din
              </Typography>
            )}

          <Typography
            variant="body2"
            className={classNames('textColor', c.productMainDescription)}
            dangerouslySetInnerHTML={showOther(main_content)}
          />
        </Box>
      </Box>
    </Link>
  )
}

const useStyles = makeStyles((theme) => ({
  productCardBoxWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
  },
  productCardBoxImage: {
    width: '100%',
    height: 'auto',
  },
  productCardImage: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
  productCardBoxTitle: {
    color: theme.palette.common.text,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  productMainDescription: {
    display: 'inline-block',
    maxHeight: 90,
    overflowY: 'hidden',
  },
  productPrice: {
    color: theme.palette.common.green,
    fontWeight: 700,
  },
}))

export default ProductCardBox
