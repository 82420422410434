import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';

import {
  productDetailsReducer,
  productListReducer,
  categoriesReducer,
} from 'libs/redux/reducers/productReducers';
import { cartReducer } from 'libs/redux/reducers/cartReducers';
import { orderReducer } from 'libs/redux/reducers/orderReducers';
import {
  articleReducers,
  articleDetailsReducer,
} from 'libs/redux/reducers/articleReducers';
import { contactFormReducer } from 'libs/redux/reducers/contactFormReducers';
import { hpTestimonialsReducer } from './reducers/hpTestimonialsReducers';

const initialState = {
  cart: {
    cartItems: sessionStorage.getItem('cartItems')
      ? JSON.parse(sessionStorage.getItem('cartItems'))
      : [],
  },
};

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  order: orderReducer,
  articleList: articleReducers,
  articleDetails: articleDetailsReducer,
  contactForm: contactFormReducer,
  categories: categoriesReducer,
  hpTestimonial: hpTestimonialsReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
