import React from 'react';
import { Grid, Container, Typography, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import theme from 'libs/theme/theme';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import "./slider-animations.css";

import { NextArrow, PrevArrow } from 'components';
import axiosUrl from 'libs/axiosUrl';

const PromotionalSpace = ({ promotionalSets }) => {
    const c = useStyles();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const showOther = (mainDescription) => {
        return {
            __html: mainDescription
        }
    }

    return (
        <Box className={c.promotionalSpaceWrapper}>
            <Container maxWidth="xl" className={classNames("padding-spacing textColor", c.sliderWrap)}>
                <Slider
                    touchDisabled={true}
                    previousButton={<PrevArrow />}
                    nextButton={<NextArrow />}
                >
                    {promotionalSets.map((item, index) => {
                        return (
                            <Grid container className={classNames("bkg-cover", c.promoContainer)} key={index}>
                                {
                                    (isMd || (index % 2 === 0)) &&
                                    <Grid item xs={12} lg={6} className={classNames("text-align-center", c.imgWrapper)}>
                                        <Box
                                            textAlign="center"
                                            display="block"
                                            m={"0 auto"}
                                            className={"plr-spacing"}
                                        >
                                            {
                                                item.slider_image && (<img
                                                    src={`${axiosUrl}${item.slider_image.formats.medium.url}`}
                                                    className={c.promoImg}
                                                    alt={'description'}
                                                />)
                                            }
                                        </Box>
                                    </Grid>
                                }

                                <Grid item xs={12} lg={6} className={classNames('padding-spacing-2', c.contentWrapper)}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography
                                            variant="h2"
                                            component="h1"
                                            className={classNames("text-align-center", c.promotionalSpaceText)}
                                            gutterBottom={true}
                                        >
                                            {item.slider_title}
                                        </Typography>
                                    </Box>

                                    <Box
                                        className={classNames(isLgUp ? "mb4" : "mb1", c.promotionalText)}
                                        dangerouslySetInnerHTML={showOther(item.slider_content)}
                                    />
                                    <Box className="text-align-center button">
                                        <Link
                                            className={classNames('btn-contained', c.orderBtn)}
                                            to={{
                                                pathname: item.slug === "prodajna-mesta" ? "/proizvodi" : `/promocija/${item.slug}`,
                                                state: { slug: item.slug }
                                            }}
                                        >
                                            Klikni da poručiš
                                        </Link>
                                    </Box>
                                </Grid>

                                {
                                    isLgUp && (index % 2 !== 0) &&
                                    <Grid item xs={12} lg={6} className={classNames("text-align-center", c.imgWrapper)}>
                                        <Box
                                            textAlign="center"
                                            display="block"
                                            m={"0 auto"}
                                            className={"plr-spacing"}
                                        >
                                            {
                                                item.slider_image && (<img
                                                    src={`${axiosUrl}${item.slider_image.formats.medium.url}`}
                                                    className={c.promoImg}
                                                    alt={'description'}
                                                />)
                                            }
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        )
                    })}
                </Slider>
            </Container>
        </Box >
    )
}

const useStyles = makeStyles((theme) => ({
    promotionalSpaceWrapper: {
        color: theme.palette.common.text,
        margin: theme.spacing(1)
    },
    promoContainer: {
        backgroundColor: theme.palette.common.white
    },
    promotionalSpaceText: {
        fontWeight: 700,
        flex: 1
    },
    imgWrapper: {
        paddingBottom: "0 !important",
        display: 'flex',
        alignItems: 'center',
    },
    promoImg: {
        maxWidth: '100%',
        maxHeight: '500px',
    },
    contentWrapper: {
        [theme.breakpoints.up("xs")]: {
            paddingTop: "0 !important",
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: `${theme.spacing(4)}px !important`,
        },
    },
    promotionalText: {
        [theme.breakpoints.up("xs")]: {
            textAlign: 'center',
            fontSize: theme.spacing(2),
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 16,
            '-webkit-box-orient': 'vertical',
        },
        [theme.breakpoints.up("sm")]: {
            '-webkit-line-clamp': 11,
        },
        [theme.breakpoints.up("md")]: {
            textAlign: 'start',
        },
        [theme.breakpoints.up("lg")]: {
            textAlign: 'start',
            fontSize: theme.spacing(2)
        },
        [theme.breakpoints.up("xl")]: {
            textAlign: 'start',
            fontSize: theme.spacing(3)
        }
    },
    sliderWrap: {
        [theme.breakpoints.up("sm")]: {
            '& .slick-arrow': {
                display: 'none!important' // override slider native styles
            },
            '&:hover': {
                '& .slick-arrow': {
                    display: 'block!important' // override slider native styles
                }
            }
        },
        '& .slick-arrow': {
            color: `${theme.palette.common.gray}!important` // override slider native styles
        },
        '& .slick-arrow:hover': {
            color: `${theme.palette.primary.main}!important` // override slider native styles
        },
        "& .slider": {
            [theme.breakpoints.up("xs")]: {
                height: 900,
            },
            [theme.breakpoints.up("sm")]: {
                height: 1000,
            },
            [theme.breakpoints.up("md")]: {
                height: 1000,
            },
            [theme.breakpoints.up("lg")]: {
                height: 530,
            },
            "& a.previousButton": {
                left: -16,
            },
            "& a.nextButton": {
                right: -16,
            }
        }
    },
    orderBtn: {
        fontSize: theme.spacing(3),
        padding: '10px 20px',
        display: 'inline-block',
        borderRadius: '4px',
        transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;`
    },
}));

export default PromotionalSpace;