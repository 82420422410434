import React, { useState } from 'react';
import {
    Box,
    Backdrop,
    Container,
    Fade,
    Grid,
    makeStyles,
    Modal,
    Typography
} from '@material-ui/core';
import classNames from 'classnames';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import { Link } from 'react-router-dom';
import Profilna from 'shared/assets/img/marina-i-miroslav.jpg';
import Nagrada1 from 'shared/assets/img/nagrada-1.jpg';
import Nagrada2 from 'shared/assets/img/nagrada-2.jpg';
import Nagrada3 from 'shared/assets/img/nagrada-3.png';
import Nagrada4 from 'shared/assets/img/nagrada-4.jpg';
import Media1 from 'shared/assets/img/media-1.png';
import Media2 from 'shared/assets/img/media-2.png';
import Media3 from 'shared/assets/img/media-3.png';
import Media4 from 'shared/assets/img/media-4.png';


const AboutUs = () => {
    const c = useStyles();
    const [open, setOpen] = useState(false);
    const [img, setImg] = useState(0);

    const handleOpen = (pictureId, e) => {
        setOpen(true);
        setImg(pictureId);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const allPictures = [
        { src: Nagrada1, pictureId: 0, alt: 'CEO' },
        { src: Nagrada2, pictureId: 1, alt: 'CEO' },
        { src: Nagrada3, pictureId: 2, alt: 'CEO' },
        { src: Nagrada4, pictureId: 3, alt: 'CEO' },
    ];

    const mediaPictures = [
        { src: Media1, href: "https://pricesadusom.com/mladi-par-iz-kragujevca-pronasao-biznis-u-sumi-od-semena-tvrdog-kao-kamen-cede-skupoceno-ulje/", alt: 'CEO' },
        { src: Media2, href: "https://www.novosti.rs/vesti/srbija.73.html:583355-Od-divlje-ruze---ulje-i-posao", alt: 'CEO' },
        { src: Media3, href: "https://www.blic.rs/biznis/mladi-par-iz-kragujevca-razradio-unosan-biznis-i-to-u-sred-sume/sj8p0bt", alt: 'CEO' },
        { src: Media4, href: "https://bistro.rs/2018/07/18/kragujevcani-marina-i-miroslav-garic-oplemenjuju-prirodnom-kozmetikom/", alt: 'CEO' },
    ];
    // 840x450

    return (
        <Container maxWidth='lg' className="lg-mg-bottom, padding-spacing textColor">
            <Grid container className='bkg-cover mb4'>
                <Grid item xs={12} className={classNames('padding-spacing-2')}>
                    <Typography variant="h2" gutterBottom={true} className={classNames("textColor text-align-center", c.aboutUsTitle)}>
                        Najbolje iz prirode - Plemenita ulja u službi ljudima
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classNames("text-align-center", "padding-spacing-2", c.imgWrapper)}>
                    <img src={Profilna} alt="o nama" />
                </Grid>

                <Grid item xs={12} className={classNames('padding-spacing-2')}>
                    <Typography className="textColor" variant="body1" style={{ marginBottom: 16 }}>
                        Dobrodošli na profil Plemenitih ulja, na web stranicu koja neguje snagu prirode. Ovde ćete pronaći odgovor kako treba da izgleda ekološka budućnost, kakva svest ljudi treba da bude u pogledu života u skladu sa prirodom. Mi želimo da naša Lena odrasta u svetu bez hemikalija.
                    </Typography>

                    <Typography className="textColor title" variant="h3">
                        Priroda u službi ljudima
                    </Typography>

                    <Typography className="textColor" variant="body1" gutterBottom={true}>
                        Svima je jasno da je hemija svuda oko nas. Koristimo praškove da peremo naše posuđe i naš veš, preparate kojima se negujemo i sa kojima se rade kozmetički i estetski tretmani, razne aditive i emulgatore u hrani… Mnogu su uplašeni zbog parabena, konzervanasa, bisfenola i raznih drugih formula kojima se i ne zna naziv ni uticaj na ljude.
                    </Typography>

                    <Typography className="textColor" variant="body1" gutterBottom={true}>
                        Mi to znamo veoma dobro. Marina je hemičar za zaštitu životne sredine, a Miroslav hemičar za naučno istraživanje i razvoj. I mi kažemo da hemija treba da je u službi ljudima, a ne da je pretnja za ljude. Treba da pričamo o prirodnim hemijskim svojstvima supstanci koje mogu da pomognu, unaprede i obogate ljudsku svakodnevicu.
                    </Typography>

                    <Typography className="textColor title" variant="h3">
                        Esencija prirode u bočici
                    </Typography>

                    <Typography className="textColor" variant="body1" gutterBottom={true}>
                        I to je zadatak koji imamo pred nama. Kako da ljudi održe zdravlje i vode brigu o svom telu, koristeći ono najbolje što priroda može da pruži, u gotovo neizmenjenom obliku, u obliku tinktura, melema, hladno ceđenih ulja, pomada, seruma, balzama… Sve ono što je nekad bila prirodna medicina i što se sad smatra “tajnama naših predaka” uz primenu najnovijih saznanja iz nauka.
                    </Typography>

                    <Typography className="textColor" variant="body1" gutterBottom={true}>
                        Želimo da naši proizvodi, naše kreme i ulja budu esencija prirode u bočici! Da svakim korišćenjem bilo kojeg od proizvoda budete svesni da je to najbolji miks sastojaka koji može da se nađe u prirodi, sa preciznom recepturom. Zato sa ponosom ističemo da su naši proizvodi - Najbolji odgovor prirode za vašu negu!
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className='bkg-cover mb4 padding-spacing-2'>
                <Typography className="textColor text-align-start title" variant="h2" gutterBottom={true} >
                    Nagrade i priznanja
                </Typography>

                <Grid container justify="space-between">
                    {
                        allPictures.map((picture) => {
                            return (
                                <Grid key={picture.pictureId} item xs={12} sm={6} lg={3} className={classNames("text-align-center", "p1")}>
                                    <Link to="#">
                                        <img onClick={() => handleOpen(picture.pictureId)} className={c.aboutUsImage} src={picture.src} alt={picture.alt} />
                                    </Link>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>

            <Grid container className='bkg-cover mb4 padding-spacing-2'>
                <Typography className="textColor text-align-start title" variant="h2" gutterBottom={true} >
                    Plemenita ulja u medijima
                </Typography>

                <Grid container justify="space-between">
                    {
                        mediaPictures.map((picture, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} lg={3} className={classNames("text-align-center", "p1")}>
                                    <a href={picture.href} rel="noopener noreferrer" target="_blank">
                                        <img className={c.aboutUsImage} src={picture.src} alt={picture.alt} />
                                    </a>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>

            <Box>
                <Modal
                    className={c.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableBackdropClick={true}
                >
                    <Fade in={open}>
                        <Box className={c.paper} display="flex" alignItems="center">
                            <img
                                className={c.modalImage}
                                src={allPictures[img].src}
                                alt="description for ceo"
                            />
                            <CancelPresentationIcon
                                className={c.exitArrow}
                                onClick={handleClose}
                            />
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    aboutUsTitle: {
        fontWeight: 600
    },

    imgWrapper: {
        '& img': {
            maxWidth: '100%',
            height: 'auto'
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
    },
    modalImage: {
        width: '100%',
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 400,
            maxHeight: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 800,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 1000,
        },
    },
    exitArrow: {
        fontSize: 80,
        position: 'absolute',
        top: '10%',
        right: '10%',
        color: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    aboutUsImage: {
        width: '100%',
        height: 'auto',
        maxWidth: 400,
        maxHeight: 300,
        objectPosition: '50% 50%',
        objectFit: 'cover',
    },
    aboutUsLinks: {
        color: theme.palette.common.green,
        "&:hover": {
            textDecoration: 'underline',
        }
    }
}))

export default AboutUs;