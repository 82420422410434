import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import { BlogCard, HashTag } from 'components'
import { useSelector } from 'react-redux'
// import classNames from 'classnames';

import { LoadingBox, MessageBox } from 'components'
import { Link, useHistory } from 'react-router-dom'

const AllArticlesPage = () => {
  const [categoryFilter, setCategoryFilter] = useState(null)
  const c = useStyles()
  const history = useHistory()

  const articleList = useSelector((state) => state.articleList)
  const { loading, error, articles } = articleList

  useEffect(() => {
    let arrayOfHashes = {}
    if (articles) {
      if (history.location.state) {
        arrayOfHashes[history.location.state] = true
      }
      articles.forEach((element) => {
        if (history.location.state !== element.hash) {
          arrayOfHashes[element.hash] = false
        }
      })
    }

    setCategoryFilter(arrayOfHashes)
  }, [articles, history.location.state])

  //Making Array from object of Hashes so I can Array.map()
  let arrayOfHashes = []
  for (const property in categoryFilter) {
    arrayOfHashes.push(property)
  } // End

  const isAnyHashActive = () => {
    for (let i = 0; i < arrayOfHashes.length; i++) {
      if (!categoryFilter[arrayOfHashes[i]]) {
        continue
      } else {
        return true
      }
    }
    return false
  }

  const showFilteredArticles = () => {
    let FilteredArticlesName = []

    for (const [key, value] of Object.entries(categoryFilter)) {
      !!value && FilteredArticlesName.push(key)
    }

    let filteredArticles = []

    for (let i = 0; i < FilteredArticlesName.length; i++) {
      for (let j = 0; j < articles.length; j++) {
        if (articles[j].hash === FilteredArticlesName[i]) {
          filteredArticles.push(
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              key={articles[j].id}
              className={'p1'}
              style={{ display: 'flex' }}
            >
              <Link to={`/blog/${articles[j].slug}`} className={c.cardLink}>
                <BlogCard
                  postTitle={articles[j].title}
                  postContent={articles[j].content}
                  thumbnail_image={articles[j].thumbnail_image}
                />
              </Link>
            </Grid>,
          )
        }
      }
    }
    return filteredArticles
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="Danger">{error}</MessageBox>
      ) : (
        <Container maxWidth="xl">
          <Grid container component="section" className={'p1'}>
            <Grid item xs={12} className={c.productBoxHeader} container>
              {arrayOfHashes.map((hash, index) => {
                return (
                  <Box p={1} key={index}>
                    <HashTag
                      onClick={() =>
                        setCategoryFilter({
                          ...categoryFilter,
                          [hash]: !categoryFilter[hash],
                        })
                      }
                      active={categoryFilter[hash]}
                    >
                      #{hash}
                    </HashTag>
                  </Box>
                )
              })}
            </Grid>
            {!isAnyHashActive()
              ? articles.map((article) => {
                  return (
                    <Grid
                      key={article.id}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={3}
                      className={'p1'}
                      style={{ display: 'flex' }}
                    >
                      <Link to={`/blog/${article.slug}`} className={c.cardLink}>
                        <BlogCard
                          postTitle={article.title}
                          postContent={article.content}
                          thumbnail_image={article.thumbnail_image}
                        />
                      </Link>
                    </Grid>
                  )
                })
              : showFilteredArticles()}
          </Grid>
        </Container>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  productBoxHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardLink: {
    display: 'flex',
    '&:hover': {
      opacity: 1,
      '& h4': {
        color: theme.palette.primary.main,
        transition: 'color 0.5s',
      },
    },
  },
}))

export default AllArticlesPage
