import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Photo1 from 'shared/assets/img/sampon-i-balzam-16.jpg';
import Photo2 from 'shared/assets/img/hydro-boost-suva-04.jpg';
import Photo3 from 'shared/assets/img/hydro-boost-masna-03.jpg';

import { useEffect } from 'react';
import { useState } from 'react';

const VideoCover = ({ headerRef }) => {
  const [headerHeight, setHeaderHeight] = useState('');

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef]);

  const c = useStyles(headerHeight);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings}>
      <Box className={c.coverPhoto1} />
      <Box className={c.coverPhoto2} />
      <Box className={c.coverPhoto3} />
    </Slider>
  );
};

const useStyles = makeStyles((theme) => ({
  coverPhoto1: {
    background: `url(${Photo1})  no-repeat center center`,
    backgroundSize: 'cover',
    height: (headerHeight) => `calc(100vh - ${headerHeight}px)`,
    width: '100%',
  },
  coverPhoto2: {
    background: `url(${Photo2})  no-repeat center center`,
    backgroundSize: 'cover',
    height: (headerHeight) => `calc(100vh - ${headerHeight}px)`,
    width: '100%',
  },
  coverPhoto3: {
    background: `url(${Photo3})  no-repeat center center`,
    backgroundSize: 'cover',
    height: (headerHeight) => `calc(100vh - ${headerHeight}px)`,
    width: '100%',
  },
}));

export default VideoCover;
