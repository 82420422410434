import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Button,
  // Tooltip
} from '@material-ui/core';
import { ReactComponent as ShoppingCardIcon } from 'shared/assets/svg/shopping-cart.svg';
import { DotPrice } from 'shared/functions/DotPrice.js';
import { HashTag } from 'components';
import AddToCart from 'ConversionAPIs/AddToCart';

const CtaSection = ({
  product,
  whichHashIsActive,
  qty,
  setQty,
  addToCartHandler,
  activeHashToTrue,
  activeHashToFalse,
}) => {
  const c = useStyles();
  const { price, price_2, quantity_1, quantity_2, id, slug } = product;

  const newPrice = DotPrice(price);
  const newPrice2 = price_2 && DotPrice(price_2);

  return (
    <Box
      className="lg-mg-bottom textColor bkg-cover padding-spacing-2"
      display="table"
    >
      {price_2 && (
        <Box display="flex">
          <Box mr={2}>
            <HashTag
              active={whichHashIsActive ? true : false}
              onClick={activeHashToTrue}
            >
              {quantity_1}
            </HashTag>
          </Box>
          <Box>
            <HashTag
              active={!whichHashIsActive ? true : false}
              onClick={activeHashToFalse}
            >
              {quantity_2}
            </HashTag>
          </Box>
        </Box>
      )}
      <Box textAlign="left" mt={1}>
        <Typography>CENA:</Typography>

        <Typography className={c.productPriceNumber}>
          {price_2
            ? whichHashIsActive
              ? `${newPrice},00 din`
              : `${newPrice2},00 din`
            : `${newPrice},00 din`}
        </Typography>
      </Box>

      <Box pt={4}>
        <Box display="flex" mb={4}>
          <Typography className={c.textQty}>Količina:</Typography>

          <select
            className={c.selectQty}
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          >
            {[...Array(20).keys()].map((x) => (
              <option key={x + 1} value={x + 1}>
                {x + 1}
              </option>
            ))}
          </select>
        </Box>

        <Box className={c.qtyAndBtnWrapper}>
          <Button
            startIcon={<ShoppingCardIcon />}
            className={c.btnAddToCart}
            variant="outlined"
            onClick={(e) => {
              addToCartHandler(
                e,
                whichHashIsActive
                  ? {
                      price: price,
                      slug,
                    }
                  : {
                      price: price_2,
                      slug,
                    },
                id
              );
              AddToCart();
            }}
          >
            Dodaj u korpu
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  productTextWrapper: {
    padding: '0 15px',
  },
  carouselWrapper: {
    '& .thumbs-wrapper': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& ul': {
      paddingLeft: 0,
      '& li': {
        textAlign: 'center',
        margin: '0px 6px',
      },
    },
  },
  productTitle: {
    fontWeight: 700,
  },
  productMainTitle: {
    padding: '20px 0',
    fontWeight: 700,
  },
  productPriceNumber: {
    color: theme.palette.common.green,
    fontWeight: 700,
    fontSize: theme.spacing(4),
  },
  qtyAndBtnWrapper: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'start',
    },
  },
  textQty: {
    fontWeight: 700,
    color: theme.palette.common.text,
  },
  selectQty: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    width: 50,
    marginLeft: 10,
  },
  btnAddToCart: {
    margin: 5,
    fontSize: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: 18,
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    paddingLeft: 50,
    paddingRight: 50,
    '& path': {
      stroke: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
  },
}));

export default CtaSection;
