import { sha256 } from 'js-sha256';
import findUserIpAddress from './findUserIpAddress';

export default async function createSendingData(values, eventId) {
  const userIp = await findUserIpAddress();

  return {
    event_name: 'Purchase',
    event_id: eventId,
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    event_source_url: window.location.href,
    user_data: {
      em: [sha256(values.email)],
      fn: [sha256(values.name)],
      ct: [sha256(values.city)],
      ph: [sha256(values.phone)],
      st: [sha256(values.address)],
      zp: [sha256(values.postCode)],
      client_user_agent: navigator.userAgent,
      client_ip_address: userIp || '0.0.0.0',
    },
    custom_data: {
      currency: 'RSD',
      value: 9999,
    },
  };
}
