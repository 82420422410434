import React from 'react'
import { Grid, Typography, Box, makeStyles } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { BlogCard, HashTag } from 'components'

const BlogAndTipsHeader = ({ articles }) => {
  const c = useStyles()
  const history = useHistory()

  const redirectToFilteredArticles = (e, value) => {
    history.push('/blog', value)
  }

  let allHashes = []
  if (articles) {
    articles.forEach((post) => {
      allHashes.push(post.hash)
    })
  }

  var uniqueHashes = allHashes.filter(function (item, i, ar) {
    return ar.indexOf(item) === i
  })

  return (
    <Box className="mb4">
      <Typography
        variant="h1"
        className={classNames(c.blogMainTitle, 'text-align-center')}
        color="primary"
      >
        Saveti
      </Typography>

      <Box
        className={c.hashBoxWrapper}
        display="flex"
        justifyContent="flex-end"
      >
        {uniqueHashes.map((hash, index) => {
          return (
            <Box
              key={index}
              p={1}
              onClick={(e) => redirectToFilteredArticles(e, hash)}
            >
              <HashTag>#{hash}</HashTag>
            </Box>
          )
        })}
      </Box>

      <Grid container style={{ justifyContent: 'center' }}>
        {articles.map((article, index) => {
          return (
            index < 4 && (
              <Grid
                key={article.id}
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                style={{ display: 'flex' }}
              >
                <Link to={`/blog/${article.slug}`} className={c.cardLink}>
                  <BlogCard
                    postTitle={article.title}
                    postContent={article.content}
                    thumbnail_image={article.thumbnail_image}
                  />
                </Link>
              </Grid>
            )
          )
        })}
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  blogMainTitle: {
    fontWeight: 700,
  },
  hashBoxWrapper: {
    width: '100%',
    textAlign: 'end',
    flexWrap: 'wrap',
  },
  cardLink: {
    display: 'flex',
    '&:hover': {
      opacity: 1,
      '& h4': {
        color: theme.palette.primary.main,
        transition: 'color 0.5s',
      },
    },
  },
}))

export default BlogAndTipsHeader
