import React from 'react';
import { Grid, Container, Typography, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import theme from 'libs/theme/theme';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import "./slider-animations.css";

import { NextArrow, PrevArrow } from 'components';
import Kamilica from "shared/assets/img/hidrolat-kamilice-01.jpg";
import Nana from "shared/assets/img/hidrolat-nane-01.jpg";
import Ruza from "shared/assets/img/hidrolat-ruze-01.jpg";
import Geranijum from "shared/assets/img/hidrolat-geranijuma-05.jpg";
import Maticnjak from "shared/assets/img/hidrolat-maticnjaka-15.jpg";
import Smilja from "shared/assets/img/hidrolat-smilja-07.jpg";
import Lavanda from "shared/assets/img/hidrolat-lavande-05.jpg";

import { nanaConst, kamilicaConst, ruzaConst, geranijumConst, maticnjakConst, smiljaConst, lavandeConst } from "shared/constants/hidrolati";

const CustomPromotionalSpace = () => {
    const c = useStyles();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const images = [
        { image: Kamilica, title: "Hidrolat Kamilice", mainContent: kamilicaConst, link: "proizvod/hidrolat-kamilice" },
        { image: Nana, title: "Hidrolat Nane", mainContent: nanaConst, link: "proizvod/hidrolat-nane" },
        { image: Ruza, title: "Hidrolat Ruže", mainContent: ruzaConst, link: "proizvod/hidrolat-ruze" },
        { image: Geranijum, title: "Hidrolat Geranijum", mainContent: geranijumConst, link: "proizvod/hidrolat-geranijuma" },
        { image: Maticnjak, title: "Hidrolat Matičnjak", mainContent: maticnjakConst, link: "proizvod/hidrolat-maticnjaka" },
        { image: Smilja, title: "Hidrolat Smilja", mainContent: smiljaConst, link: "proizvod/hidrolat-smilja" },
        { image: Lavanda, title: "Hidrolat Lavande", mainContent: lavandeConst, link: "proizvod/hidrolat-lavande" }
    ]

    return (
        <Box className={c.promotionalSpaceWrapper}>
            <Container maxWidth="xl" className={classNames("padding-spacing textColor", c.sliderWrap)}>
                <Slider
                    touchDisabled={true}
                    previousButton={<PrevArrow />}
                    nextButton={<NextArrow />}
                >
                    {images.map((item, index) => {
                        return (
                            <Grid container className={classNames("bkg-cover", c.promoContainer)} key={index}>
                                <Grid item lg={1} />
                                {
                                    (isMd || (index % 2 === 0)) &&
                                    <Grid item xs={12} lg={4} className={classNames("text-align-center", c.imgWrapper)}>
                                        <Box
                                            textAlign="center"
                                            display="block"
                                            m={"0 auto"}
                                            className={"plr-spacing"}
                                        >
                                            <img
                                                src={item.image}
                                                className={c.promoImg}
                                                alt={'hidrolati'}
                                            />
                                        </Box>
                                    </Grid>
                                }

                                <Grid item xs={12} lg={6} className={classNames('padding-spacing-2', c.contentWrapper)}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography
                                            variant="h2"
                                            component="h1"
                                            className={classNames("text-align-center", c.promotionalSpaceText)}
                                            gutterBottom={true}
                                        >
                                            {item.title}
                                        </Typography>
                                    </Box>

                                    <Box className={classNames(isLgUp ? "mb4" : "mb1", c.promotionalText)}>
                                        {item.mainContent}
                                    </Box>

                                    <Box className="text-align-center button">
                                        <Link
                                            className={classNames('btn-contained', c.orderBtn)}
                                            // onClick={e => e.preventDefault()}
                                            to={{
                                                pathname: item.link,
                                            }}
                                        >
                                            Klikni da poručiš
                                        </Link>
                                    </Box>
                                </Grid>

                                {
                                    isLgUp && (index % 2 !== 0) &&
                                    <Grid item xs={12} lg={4} className={classNames("text-align-center", c.imgWrapper)}>
                                        <Box
                                            textAlign="center"
                                            display="block"
                                            m={"0 auto"}
                                            className={"plr-spacing"}
                                        >
                                            <img
                                                src={item.image}
                                                className={c.promoImg}
                                                alt={'hidrolati'}
                                            />
                                        </Box>
                                    </Grid>
                                }
                                <Grid item lg={1} />
                            </Grid>
                        )
                    })}
                </Slider>
            </Container>
        </Box >
    )
}

const useStyles = makeStyles((theme) => ({
    promotionalSpaceWrapper: {
        color: theme.palette.common.text,
        margin: theme.spacing(1)
    },
    promoContainer: {
        backgroundColor: theme.palette.common.white
    },
    promotionalSpaceText: {
        fontWeight: 700,
        flex: 1
    },
    imgWrapper: {
        paddingBottom: "0 !important",
        display: 'flex',
        alignItems: 'center',
    },
    promoImg: {
        maxWidth: '100%',
        maxHeight: '500px',
    },
    contentWrapper: {
        [theme.breakpoints.up("xs")]: {
            paddingTop: "0 !important",
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: `${theme.spacing(4)}px !important`,
        },
    },
    promotionalText: {
        [theme.breakpoints.up("xs")]: {
            textAlign: 'center',
            fontSize: 18,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 16,
            '-webkit-box-orient': 'vertical',
        },
        [theme.breakpoints.up("sm")]: {
            '-webkit-line-clamp': 11,
            fontSize: 20
        },
        [theme.breakpoints.up("md")]: {
            textAlign: 'start',
            fontSize: 20
        },
        [theme.breakpoints.up("lg")]: {
            textAlign: 'start',
            fontSize: 22
        },
        [theme.breakpoints.up("xl")]: {
            textAlign: 'start',
            fontSize: 24
        }
    },
    sliderWrap: {
        [theme.breakpoints.up("sm")]: {
            '& .slick-arrow': {
                display: 'none!important' // override slider native styles
            },
            '&:hover': {
                '& .slick-arrow': {
                    display: 'block!important' // override slider native styles
                }
            }
        },
        '& .slick-arrow': {
            color: `${theme.palette.common.gray}!important` // override slider native styles
        },
        '& .slick-arrow:hover': {
            color: `${theme.palette.primary.main}!important` // override slider native styles
        },
        "& .slider": {
            [theme.breakpoints.up("xs")]: {
                height: 900,
            },
            [theme.breakpoints.up("sm")]: {
                height: 1000,
            },
            [theme.breakpoints.up("md")]: {
                height: 1000,
            },
            [theme.breakpoints.up("lg")]: {
                height: 530,
            },
            "& a.previousButton": {
                left: -16,
            },
            "& a.nextButton": {
                right: -16,
            }
        }
    },
    orderBtn: {
        fontSize: theme.spacing(3),
        padding: '10px 20px',
        display: 'inline-block',
        borderRadius: '4px',
        transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;`
    },
}));

export default CustomPromotionalSpace;