import React from 'react';
import { Grid, makeStyles, Avatar, Typography } from '@material-ui/core';
import classNames from "classnames";

const UserTopComment = () => {
    const c = useStyles();

    return (
        <Grid container component="section" className={classNames("bkg-cover", "p1", "mb1")}>
            <Grid item xs={12} md={2} container justify="center" alignItems="center" className={"p1"}>
                <Avatar alt="avatar" className={c.userTopCommentAvatar} />
            </Grid>
            <Grid item xs={12} md={10} className={c.topCommentTextWrapper}>
                <Typography variant="h5" className={classNames("p1", "title", "textColor")}>
                    TopComment title
                </Typography>
                <Typography className={"p1 textColor"}>
                    {[...Array(5)].map(() => "Lorem Ipsum is simply dummy text of the printing and typesetting industry.")}
                </Typography>
            </Grid>
        </Grid>

    )
}

const useStyles = makeStyles((theme) => ({
    userTopCommentAvatar: {
        width: 135,
        height: 135
    },
    topCommentTextWrapper: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'start'
        }
    }
}));

export default UserTopComment;