import React, { useState } from 'react'
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Card,
  Modal,
  Fade,
  Backdrop,
  Container,
} from '@material-ui/core'
import classNames from 'classnames'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import { Link } from 'react-router-dom'
import axiosUrl from 'libs/axiosUrl'
import { useMediaQuery } from '@material-ui/core'
import theme from 'libs/theme/theme'

const Testimonials = ({
  // testimonials_content,
  testimonials_gallery,
}) => {
  const c = useStyles()
  const [open, setOpen] = useState(false)
  const [img, setImg] = useState(0)

  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))

  const handleOpen = (pictureId, e) => {
    setOpen(true)
    setImg(pictureId)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const showPrevious = () => {
    if (img !== 0) setImg(img - 1)
  }

  const showNext = () => {
    if (img !== testimonials_gallery.length - 1) setImg(img + 1)
  }

  // const showOther = (testimonials_content) => {
  //     return {
  //         __html: testimonials_content
  //     }
  // }

  return (
    <Container
      maxWidth="xl"
      className="lg-mg-bottom, padding-spacing textColor"
    >
      <Box
        className={classNames(
          c.testimonialsWrapper,
          'padding-spacing bkg-cover m1',
        )}
      >
        <Typography
          variant="h2"
          className={classNames('text-align-center', 'title')}
          gutterBottom={true}
          color="primary"
        >
          Utisci korisnica
        </Typography>

        {/* <Grid container justify="space-between">
                    {
                        testimonials_gallery.length > 0 && (
                            testimonials_gallery.map((picture, index) => {
                                // testimonials.map((tesimonial, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={6} lg={3} className={classNames("text-align-center", "p1", c.cardWrapper)}>
                                        <Link to="#">
                                            <Card
                                        elevation={4}
                                        className={classNames(c.card, "textColor")}
                                    >
                                        {tesimonial}
                                    </Card>
                                            <img onClick={() => handleOpen(picture.pictureId)} className={c.testimonialsImage} src={picture.src} alt={picture.alt} />
                                        </Link>
                                    </Grid>
                                )
                            })
                        )
                    }
                </Grid> */}

        {/* <Grid item xs={12}>
                    <Box mb={2}>
                        <Typography
                            variant="body2"
                            dangerouslySetInnerHTML={showOther(testimonials_content)}
                        />
                    </Box>
                </Grid> */}

        {testimonials_gallery && testimonials_gallery.length > 0 && (
          <>
            <Grid
              container
              style={{
                justifyContent: 'center',
                flexWrap: isMdDown ? 'wrap' : 'nowrap',
              }}
            >
              {testimonials_gallery.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classNames('text-align-center', 'p1')}
                  >
                    <Link
                      to="#"
                      style={{ margin: '0 auto' }}
                      onClick={() => handleOpen(index)}
                    >
                      <Card
                        elevation={4}
                        className={classNames(c.card, 'textColor')}
                      >
                        <img
                          className={c.testimonialsImage}
                          src={`${axiosUrl}${item.formats.thumbnail.url}`}
                          alt="testimonial"
                        />
                      </Card>
                    </Link>
                  </Grid>
                )
              })}
            </Grid>

            <Box>
              <Modal
                className={c.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                disableBackdropClick={true}
              >
                <Fade in={open}>
                  <Box className={c.paper} display="flex" alignItems="center">
                    <ChevronLeftIcon
                      className={classNames(c.arrows, c.leftArrow)}
                      onClick={showPrevious}
                      style={{ visibility: img !== 0 ? 'visible' : 'hidden' }}
                    />
                    <img
                      className={c.modalImage}
                      src={`${axiosUrl}${testimonials_gallery[img].url}`}
                      alt="testimonial"
                    />
                    <ChevronRightIcon
                      className={classNames(c.arrows, c.rightArrow)}
                      onClick={showNext}
                      style={{
                        visibility:
                          testimonials_gallery.length - 1 !== img
                            ? 'visible'
                            : 'hidden',
                      }}
                    />
                    <CancelPresentationIcon
                      className={c.exitArrow}
                      onClick={handleClose}
                    />
                  </Box>
                </Fade>
              </Modal>
            </Box>
          </>
        )}
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
    flexWrap: 'wrap',
    flexDirection: 'column',
    flex: 1,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  testimonialsWrapper: {
    width: '100%',
  },
  textTestimonials: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  testimonialsImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 400,
    maxHeight: 275,
    objectPosition: '50% 50%',
    objectFit: 'cover',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  modalImage: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
      maxHeight: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 800,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1000,
    },
  },
  arrows: {
    fontSize: 100,
    color: 'white',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  leftArrow: {
    [theme.breakpoints.up('xs')]: {
      position: 'absolute',
      top: '45%',
      left: 0,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  },
  rightArrow: {
    [theme.breakpoints.up('xs')]: {
      position: 'absolute',
      top: '45%',
      right: 0,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  },
  exitArrow: {
    fontSize: 80,
    position: 'absolute',
    top: '10%',
    right: '10%',
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}))

export default Testimonials
