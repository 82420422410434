export const kamilicaConst = `Zbog snažnog protivupalnog i antialergijskog delovanja, možeš ga koristiti za negu, bez obzira na to koji tip kože imaš.
    Mogu ga koristiti i osobe sa rozaceom, ekcemom, psorijazom ili aknama, a delotvoran je i kod ublažavanja opekotina od sunčanja jer Ima umirujuće dejstvo.
    Ujedno je S.O.S. za opekotine i ogrebotine.
    Možeš ga koristiti i kao kupku koja olakšava simptome vaginalnih i urinarnih infekcija  kao oblogu za umorne oči i ispucale kapilare.
    Može se koristiti i za negu jako osetljive kože dece i beba za pelenski osip i nadraženu kožu.
    A zbog svojih umirujućih svojstava često se koristi u aromaterapiji.
`

export const nanaConst = `Hidrolat nane je pogodan za masnu i osetljivu kožu sklonu proširenim porama.
    Smiruje i hladi kožu, što naročito prija sledećim stanjima: ujedi insekata, ekcemi, opekotine od sunca, psorijaza, seboreja .
    Sprečiće crvenilo kože nakon brijanja ili depilacije.
    Deluje umirujuće na tvoju kožu, ublažava svrab i suvu i iritiranu kožu.
    Ima protivupalno dejstvo, a pomaže i u regeneraciji kože.
    Blago hladi, što naročito prija koži sklonoj aknama i bubuljicama.
`

export const geranijumConst = `Pogodan je za sve tipove kože.
    Hidrolat geranijuma ima osvežavajuće i regenerativno dejstvo na kožu.
    Može se koristiti kao dezodorans i  prirodni parfem ali i u aromaterapiji.
    Obnavlja ćelije i regeneriše kožu, a dobar je I za hidrataciju kože.
    Ublažiće upalna mesta nastala od akni i bubuljica.
    Deluje na gljivice, a pogoduje i nezi kože sa sledećim stanjima: prošireni kapilari, sitne rane i krvarenja, upaljene vene, opekotine, dermatitis, ekcem ,lišajevi, kao I za negu bora.
`

export const ruzaConst = `Ako želiš prirodno zdravu, lepu i negovanu kožu, onda je tvoj izbor hidrolat ruže.
    Hidrolat ruže odgovara svim tipovima kože, a naročito prija osetljivoj i suvoj koži kao i kod prvih znakova starenja, jer održava ravnotežu vlažnosti kože, odlično hidrira i regeneriše kožu i time joj vraća vitalnost
    Odlično tonira, čisti i hrani zbog svog izuzetnog sastava koji obiluje vitaminima (A i C) i antioksidansima
    Zateže pore i sprešava bore i upija suvišnu masnoću sa lica, a da pritom ne isušuje kožu
    Može smanjiti tamne kolutove oko očiju 
    Ružina vodica sadrži moćne sastojke koji mogu da pomognu tvom licu da izgleda zdravo i sveže
`

export const maticnjakConst = `Može se koristiti čišćenje, negu i hidrataciju svih tipova kože, posebno za suvu, zrelu ili upaljenu kožu lica. 
    Idealan je izbor za čišćenje i detoksifikaciju kože sa aknama.
    Zbog svojih antibakterijskih svojstava hidrolat matičnjaka je koristan kod mnogih kožnih problema: ujeda insekata, ekcema, opekotina od sunca, psorijaze, seboreje, a sprečava i pojavu akni i bubuljica.
    Može se koristiti za ispiranje usta nerazblažen ili razblažen sa destilovanom vodom u razmeri 1:1.
    Kao i za negu intimne regije, gde se hidrolat matičnjaka kombinuje sa destilovanom vodom i koristi dva puta dnevno.
    U letnjim mesecima može se koristiti za osvežavanje kože lica i tela.
`

export const smiljaConst = `Hidrolat smilja odgovara svim tipovima kože,  deluje protivupalno i veoma je koristan protiv modrica i hematoma, a možeš ga u vidu obloga koristiti za smanjivanje podočnjaka.
    Ukoliko je tvoja koža osetljiva i u nekim periodima dodatno umorna onda je za tebe idealno rešenje hidrolat smilja.
    Smilje je odlično sredstvo protiv bora, mrlja i drugih znakova starenja kože i poznato je po tome da regeneriše kožu i pojačava protok krvi, pa ga možeš upotrebiti za podsticanje zarastanja rana ili da smanjiš neki ožiljak.
    Možeš ga koristiti za hidrataciju suve kože.
    Hidrolat smilja reguiše sebum, pomaže u uspostavljanju pH balansa kože, minimalizuje pore, kontroliše crvenilo i smanjuje bore.
`

export const lavandeConst = `
    Hidrolat lavande je pogodan za sve tipove kože.
    Tvojoj koži će vratiti vlažnost ukoliko je suva, regeneriše je a da pritom ne narušava prirodni Ph kože.
    Pomoći će ti kod otoka od bubuljica i ubrzaće zarastanje ranica. 
    Pomaže kod ujeda insekata – mala količina hidrolata lavande može da smanji svrab i ublaži otok.
    Ukoliko te muči perut, utapkaj nekoliko kapi hidrolata lavande u svoj omiljeni šampon ili nanesi hidrolat direktno na kožu glave ili u koren kose.
    Hidrolat lavande možete koristiti i nakon depilacije, naprskajte na izdepiliranu regiju i crvenkaste tačkice će nestati za 10 minuta.
`