import React from 'react';
import { Typography, Box, Grid, makeStyles, Container } from '@material-ui/core';
import classNames from 'classnames';

const Benefits = ({ benefits }) => {
    const c = useStyles();

    const {
        benefit_title_1,
        benefit_title_2,
        benefit_title_3,
        benefit_title_4,
        benefit_content_1,
        benefit_content_2,
        benefit_content_3,
        benefit_content_4
    } = benefits;

    return (
        <Container maxWidth="xl" className="padding-spacing">
            <Grid
                container
                justify="space-around"
                className={classNames(c.benefitsWrapper, "padding-spacing-2", "bkg-cover", "textColor m1")}
            >
                <Grid item xs={12}>
                    <Typography
                        className={classNames("text-align-center", "title", c.benefitsMainTitle)}
                        variant="h2"
                        color="primary"
                    >
                        Benefiti
                    </Typography>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box mb={6} fontWeight={700}>
                        <Typography
                            variant="h4"
                            gutterBottom={true}
                            className={c.benefitTitle}
                            color="primary"
                        >
                            {benefit_title_1}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={c.benefitsText}
                        >
                            {benefit_content_1}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box mb={6} fontWeight={700}>
                        <Typography
                            variant="h4"
                            gutterBottom={true}
                            className={c.benefitTitle}
                            color="primary"
                        >
                            {benefit_title_2}
                        </Typography>
                        <Typography variant="body2" className={c.benefitsText}>
                            {benefit_content_2}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box mb={6} fontWeight={700}>
                        <Typography
                            variant="h4"
                            gutterBottom={true}
                            className={c.benefitTitle}
                            color="primary"
                        >
                            {benefit_title_3}
                        </Typography>
                        <Typography variant="body2" className={c.benefitsText}>
                            {benefit_content_3}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box mb={6} fontWeight={700}>
                        <Typography
                            variant="h4"
                            gutterBottom={true}
                            className={c.benefitTitle}
                            color="primary"
                        >
                            {benefit_title_4}
                        </Typography>
                        <Typography variant="body2" className={c.benefitsText}>
                            {benefit_content_4}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    benefitsWrapper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    benefitsMainTitle: {
        marginBottom: theme.spacing(1),
    },
    benefitTitle: {
        fontWeight: 700,
    }
}));

export default Benefits;