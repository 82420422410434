import React from 'react'
import { useSelector } from 'react-redux'

import {
  VideoSection,
  SpecialOffer,
  SocialIntegration,
  BlogTipsNews,
  ContactUsSection,
  CustomPromotionalSpace,
} from 'containers/HomePage'
import { LoadingBox, MessageBox, Testimonials } from 'components'

const HomePage = ({ instaFeed, headerRef }) => {
  const hpTestimonial = useSelector((state) => state.hpTestimonial)
  const { loading, error, hpTestimonials } = hpTestimonial

  return (
    <>
      <div style={{ overflowX: 'hidden' }}>
        <VideoSection headerRef={headerRef} />
      </div>
      <CustomPromotionalSpace />
      <SpecialOffer />
      <BlogTipsNews />
      <div style={{ overflowX: 'hidden' }}>
        <SocialIntegration instaFeed={instaFeed} />
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="Danger">{error}</MessageBox>
      ) : (
        <Testimonials
          testimonials_gallery={hpTestimonials.testimonials_gallery}
        />
      )}
      <ContactUsSection />
    </>
  )
}

export default HomePage
