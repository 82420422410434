import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

import theme from 'libs/theme/theme';

const HashTag = ({ children, active, onClick }) => {
    const c = useStyles(active);

    return (
        <Button
            onClick={onClick}
            className={c.btnHash}
            variant="outlined"
            style={{
                color: active ? theme.palette.common.white : theme.palette.primary.main,
                backgroundColor: active ? theme.palette.primary.main : 'inherit',
            }}
        >
            {children}
        </Button>
    )
}

const useStyles = makeStyles((theme) => ({
    btnHash: {
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
        },
        [theme.breakpoints.up('xs')]: {
            fontSize: 10,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        }
    },
}))

export default HashTag;