import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import PropsRoute from 'shared/functions/PropsRoute'
import {
  HomePage,
  ContactPage,
  AboutUs,
  ProductPage,
  CartPage,
  AllProductsPage,
  OrderPage,
  AllArticlesPage,
  BlogAndTipsPage,
  ArticlePage,
  RightsAndObligationsOfConsumers,
  DeliveryAndPayment,
  PrivacyProtection,
  ReclamationAndReturnOfGoods,
} from '../containers'

const Routing = ({ instaFeed, headerRef }) => {
  return (
    <Switch>
      <PropsRoute path="/kontakt" component={ContactPage} />
      <PropsRoute path="/o-nama" component={AboutUs} />
      <PropsRoute path="/proizvodi" component={AllProductsPage} />
      <PropsRoute path="/proizvod/:slug?" component={ProductPage} />
      <PropsRoute path="/promocija/:slug?" component={ProductPage} />
      <PropsRoute path="/korpa/naruci" component={OrderPage} />
      <PropsRoute path="/korpa/:id?" component={CartPage} />
      <PropsRoute path="/blog" component={AllArticlesPage} exact />
      <PropsRoute path="/blog-i-saveti" component={BlogAndTipsPage} exact />
      <PropsRoute path="/blog/:slug?" component={ArticlePage} />
      <PropsRoute
        path="/prava-i-obaveze-potrosaca"
        component={RightsAndObligationsOfConsumers}
      />
      <PropsRoute path="/dostava-i-placanje" component={DeliveryAndPayment} />
      <PropsRoute path="/zastita-privatnosti" component={PrivacyProtection} />
      <PropsRoute
        path="/reklamacija-i-povracaj-robe"
        component={ReclamationAndReturnOfGoods}
      />
      <PropsRoute
        path="/"
        component={() => (
          <HomePage instaFeed={instaFeed} headerRef={headerRef} />
        )}
      />
    </Switch>
  )
}

export default memo(Routing)
