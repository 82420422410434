import React, { useEffect, useState } from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { BlogCard } from 'components'
import { Link } from 'react-router-dom'

const BlogTipsNews = () => {
  const c = useStyles()
  const articleList = useSelector((state) => state.articleList)
  const { articles } = articleList
  const [last3postsById, setLast3postsById] = useState(null)

  useEffect(() => {
    if (articles.length > 0) {
      const last3postsById = articles.filter((post, index) => {
        return (
          (index === articles.length - 1 ||
            index === articles.length - 2 ||
            index === articles.length - 3 ||
            index === articles.length - 4) &&
          post
        )
      })
      setLast3postsById(last3postsById)
    }
  }, [articles])

  if (!last3postsById) return false

  return (
    <Container maxWidth="xl" className="padding-spacing" component="section">
      <Typography
        variant="h2"
        className={'text-align-center title'}
        gutterBottom={true}
        color="primary"
      >
        Novosti
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          className={c.articleCardWrapper}
        >
          <Link to={`/blog/${last3postsById[3].slug}`} className={c.cardLink}>
            <BlogCard
              postTitle={last3postsById[3].title}
              postContent={last3postsById[3].content}
              thumbnail_image={last3postsById[3].thumbnail_image}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          className={c.articleCardWrapper}
        >
          <Link to={`/blog/${last3postsById[2].slug}`} className={c.cardLink}>
            <BlogCard
              postTitle={last3postsById[2].title}
              postContent={last3postsById[2].content}
              thumbnail_image={last3postsById[2].thumbnail_image}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          className={c.articleCardWrapper}
        >
          <Link to={`/blog/${last3postsById[1].slug}`} className={c.cardLink}>
            <BlogCard
              postTitle={last3postsById[1].title}
              postContent={last3postsById[1].content}
              thumbnail_image={last3postsById[1].thumbnail_image}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          className={c.articleCardWrapper}
        >
          <Link to={`/blog/${last3postsById[0].slug}`} className={c.cardLink}>
            <BlogCard
              postTitle={last3postsById[0].title}
              postContent={last3postsById[0].content}
              thumbnail_image={last3postsById[0].thumbnail_image}
            />
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  articleCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardLink: {
    display: 'flex',
    flex: 1,
    '&:hover': {
      opacity: 1,
      '& h4': {
        color: theme.palette.primary.main,
        transition: 'color 0.5s',
      },
    },
  },
}))

export default BlogTipsNews
