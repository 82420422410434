import React from 'react';
import { ArrowBackIosSharp } from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';

import theme from 'libs/theme/theme';


const PrevArrow = ({ className, onClick }) => {
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ArrowBackIosSharp color={"primary"} style={{ fontSize: 60, opacity: isSmDown ? 0.3 : 1 }} className={className} onClick={onClick} />
    );
}

export default PrevArrow;