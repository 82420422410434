import { GET_HP_TESTIMONIALS_REQUEST, GET_HP_TESTIMONIALS_SUCCESS, GET_HP_TESTIMONIALS_FAIL } from 'shared/constants/hpTestimonialsConstant';
import axios from 'axios';
import axiosUrl from 'libs/axiosUrl';

export const getHpTestimonials = () => async (dispatch) => {
    dispatch({ type: GET_HP_TESTIMONIALS_REQUEST })

    try {
        const { data } = await axios.get(`${axiosUrl}/hp-testimonials`)
        dispatch({ type: GET_HP_TESTIMONIALS_SUCCESS, payload: data[0] })
    } catch (error) {
        dispatch({ type: GET_HP_TESTIMONIALS_FAIL, payload: error.message })
    }
}