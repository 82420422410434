export const qaLong = [
  {
    title: 'Ulje na masnu kožu',
    text: `Često smo u zabludi da će ulje naneto na masnu kožu lica dodatno zamastiti kožu, jer to nije nešto što bi iko želeo da duplira.
                Ali, činjenice su sledeće...
                Kada nanesemo ulje na kožu, dajemo signal koži da je koža već dovoljno masna i da nema potrebe za dodatnim lučenjem sebuma, na taj način "prevarimo" naše lojne žlezde, a koža se nakon nekog vremena navikne na ulje izbalansira se lučenje sebuma i postane normalna.
                Dakle kada na masnu kožu stavite ulje ona neće imati potrebe da proizvodi svoje jer će dobiti sve što joj treba.`,
  },
  {
    title: 'Imam ekcem, koje ulje da koristim?',
    text: `Za ekcem je preporuka ulje divlje ruže, njega smeju i bebe da koriste za ekceme. Ukoliko nisu korišćeni kortikosteroidi potrebno je oko 2 nedelje svakodnevnog korišćenja da bi se ekcwm uklonio. Ako su pak korišćeni kortikosteroidi potrebno je više vremena budući da oni oštete i mikrofloru i epitel kože.`,
  },
  {
    title: 'Prvo krema pa ulje ili obrnuto?',
    text: `Na kožu se prvo nanosi krema, a nakon nje ulje ili uljani serum. Zašto je to tako?
                Ulje ima sposobnost da prodre kroz kremu i u dublje slojeve kože i da hrani i neguje kožu. Ovaj način nanošenja spada u lejering tehniku nege gde se ulja nanose na kraju kao završnica nege jer se na taj način uslovno rečeno zaključava hidratacija, tj. ulja sprečavaju gubljenje vlage iz kože.
                Kada bi se nanelo prvo ulje, pa krema, krema ne bi uspela da prodre kroz uljani film na koži.`,
  },
  {
    title: 'Koje ulje koristiti za čišćenje lica i skidanje šminke?',
    text: `Na tržištu postoji mnogo proizvoda za ovu namenu, ali ako želiš svojoj koži da pružiš vrhunski ugođaj i da ne brineš šta si nanela na kožu, naša preporuka je hladno ceđeno ulje kajsije. Poznata nam je tehnika duplog čišćenja lica koja podrazumeva uklanjanje nečistoća sa kože uljima, a zatim skidanje viška ulja sa kože umivalicom.`,
  },
]

export const qaShort = [
  {
    title: 'Koje ulje koristiti za uzrazito suvu i dehidriranu kožu ?',
    text: `Za suvu kozu je preporuka ili ulje jezgra sljive ili ulje semena kupine.
                Ulje jezgra sljive se nanosi na suvu kozu a ulje semena kupine na vlaznu kozu lica`,
  },
  {
    title:
      'Da li se melem i mix za strije mogu koristiti preventivno u trudnoći ?',
    text: `I melem i mix za strije se mogu koristiti preventivno za strije u trudnoći, oba se takodje smeju koristiti i u periodu dojenja`,
  },
  {
    title: 'Šta koristiti za hiperpigmentacijske fleke ?',
    text: `Za hiperpigmentacijske fleke je preporuka ulje divlje ruze i ulje semena kupine.
                 Gde se preko dana koristi ulje divlje ruze a uvece ulje semena kupine`,
  },
  {
    title: 'Da li smeju deca da koriste melem za bradavice i mladeže?',
    text: `Deca smeju da koriste melem za bradavice i mladeze jer ne sadrzi nikakve stetne hemikalije emulgatore i konzervanse
                Glavna aktivna komponenta melema za mladeze i bradavice je macerat Ruse trave`,
  },
]
