import { withStyles } from '@material-ui/core'

const styles = (theme) => ({
  '@global': {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    html: {
      overflowY: 'scroll',
    },
    '*': {
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
    a: {
      color: 'inherit',
      cursor: 'pointer',
      textDecoration: 'none',
      display: 'inline-block',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '*:focus': {
      outline: 0,
    },
    '.bkg-cover': {
      borderRadius: '10px !important',
      border: `1px solid ${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    '.text-white *': {
      color: `${theme.palette.common.white} !important`,
    },
    '.text-align-start': {
      textAlign: 'start !important',
    },
    '.text-align-center': {
      textAlign: 'center !important',
    },
    '.text-align-end': {
      textAlign: 'end !important',
    },
    '.text-upper-case': {
      textTransform: 'uppercase !important',
    },
    '.position-relative': {
      position: 'relative !important',
    },
    //Currently is not in use, but maby we will need it
    '.capitalizedFirstLetter': {
      '&::first-letter': {
        textTransform: 'capitalize !important',
      },
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(8)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(4)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
    },
    '.title': {
      fontWeight: '700 !important',
    },
    '.textColor': {
      color: '#8c8c8c !important',
    },
    '.padding-spacing': {
      padding: `${theme.spacing(8)}px !important`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(4)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(2)}px !important`,
      },
    },
    '.mt20': {
      marginTop: `${theme.spacing(1)}px !important `,
    },
    '.m1': {
      margin: `${theme.spacing(1)}px !important`,
    },
    '.mb1': {
      marginBottom: `${theme.spacing(1)}px !important`,
    },
    '.mb4': {
      marginBottom: `${theme.spacing(4)}px !important`,
    },
    '.p1': {
      padding: `${theme.spacing(1)}px !important`,
    },
    '.pb1': {
      paddingBottom: `${theme.spacing(1)}px !important`,
    },
    '.padding-spacing-2': {
      [theme.breakpoints.up('xs')]: {
        padding: `${theme.spacing(2)}px !important`,
      },
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(3)}px !important`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${theme.spacing(4)}px !important`,
      },
    },
    '.plr-spacing': {
      [theme.breakpoints.up('xs')]: {
        padding: `${theme.spacing(2)}px !important`,
      },
    },
    '.btn-contained': {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `1px solid ${theme.palette.primary.main} !important`,
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.palette.common.white} !important`,
        border: `1px solid ${theme.palette.primary.main} !important`,
      },
    },
    '.alert': {
      padding: '1rem',
      border: '0.1rem solid transparent',
      borderRadius: '0.5rem',
    },
    '.alertInfo': {
      color: '#2020a0',
      backgroundColor: '#e0e0ff',
    },
    '.alertDanger': {
      color: '#a02020',
      backgroundColor: '#ffe0e0e0',
    },
    '.carousel .slide': {
      background: 'none',
    },

    '.image': {
      position: 'relative',
      textAlign: 'center',
    },
    '.image-style-side': {
      float: 'right',
      marginLeft: `var(--ck-image-style-spacing)`,
      maxWidth: '50%',
    },
    '.image img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: '90%',
      minWidth: '50px',
    },
    '.image > figcaption': {
      captionSide: 'bottom',
      wordBreak: 'break-word',
      color: '#333',
      backgroundColor: '#f7f7f7',
      padding: '.6em',
      fontSize: '.75em',
      outlineOffset: '-1px',
    },
  },
})

function globalStyles() {
  return null
}

export default withStyles(styles, { withTheme: true })(globalStyles)
