import React from 'react';
import { Box } from '@material-ui/core';

const MessageBox = (props) => {
    const { variant, children } = props;
    return (
        <Box className={`alert alert${variant || 'Info'}`}>
            {children}
        </Box>
    )
}

export default MessageBox;