import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, makeStyles } from '@material-ui/core'
import axios from 'axios'

import { NavBar, Footer, LoadingBox, MessageBox } from 'components'
import { Routing } from '../containers'
import { getProducts, getCategories } from 'libs/redux/actions/productActions'
import { getAllArticles } from 'libs/redux/actions/articleActions'
import bkgPhoto from 'shared/assets/img/bkc-image.png'
import { getPromotions } from 'libs/redux/actions/promotionActions'
import { getHpTestimonials } from 'libs/redux/actions/hpTestimonialsActions'
import axiosUrl from 'libs/axiosUrl'
import { useRef } from 'react'

const Main = () => {
  const [instaFeed, setInstaFeed] = useState([])
  const c = useStyles()
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories)
  const { loading, error } = categories

  const token =
    'IGQVJWSU9ZAZAHdmTGdtVGZAENGY4SlhoT09uLVZAHNk1mNV9INXVIRzk5QTNTazlBMmU1eFJyeWRQb3RyX2ticEh0QjRNeVpFZA2dKTG1ZAaGZAaNXNUd05uSmNjUXBXY0RZAckxNV2UxRjV6ZAlI4b3BOMTJscQZDZD'
  const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=media_url,permalink,thumbnail_url`

  useEffect(() => {
    const refreshUrl = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${token}`
    const sixWeeks = 362880 // 6 nedelja  -0000 bez zadnje cetiri nule, mongo baza ne moze da ima previse brojeva
    const currentTimeCounterId = process.env.REACT_APP_CURRENT_TIME_COUNTER

    const getTime = async () => {
      const { data } = await axios.get(`${axiosUrl}/counters`)
      const timeToRefreshToken = data[1].Counter

      const date = new Date()
      const currentTimeMilliseconds = date.getTime().toString()
      const stringedAndSlicedCurrentTime = currentTimeMilliseconds.slice(
        0,
        currentTimeMilliseconds.length - 4,
      )

      if (Number(stringedAndSlicedCurrentTime) >= timeToRefreshToken) {
        // refresh token
        axios.get(refreshUrl)
        // set new timer
        axios.put(`${axiosUrl}/counters/${currentTimeCounterId}`, {
          Counter: timeToRefreshToken + sixWeeks,
        })
      }
    }

    getTime()
  }, [])

  useEffect(() => {
    const getInstaFeed = async () => {
      try {
        const { data } = await axios.get(url)
        setInstaFeed(data.data)
      } catch (error) {
        console.log(error)
      }
    }
    getInstaFeed()
  }, [url])

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getProducts())
    dispatch(getAllArticles())
    dispatch(getPromotions())
    dispatch(getHpTestimonials())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const headerRef = useRef(null)

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="Danger">{error}</MessageBox>
      ) : (
        <Box className={c.pageContainer} id="top">
          <Box className={c.contentWrap} component="main">
            <NavBar headerRef={headerRef} />
            <Routing instaFeed={instaFeed} headerRef={headerRef} />
          </Box>
          <Footer />
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: theme.palette.secondary.main,
    background: `url(${bkgPhoto}) no-repeat fixed center`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  contentWrap: {
    flex: 1,
  },
}))

export default Main
