import React from 'react'
import {
  Container,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { SocialIcon } from 'components'

const Footer = () => {
  const c = useStyles()
  let currentDate = new Date()
  return (
    <Box className={c.footerWrapper}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={8} lg={6}>
            <List className={classNames(c.list)}>
              <ListItem>
                <Link to="/o-nama">
                  <ListItemText>O nama</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/proizvodi">
                  <ListItemText>Proizvodi</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/blog-i-saveti">
                  <ListItemText>Saveti</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/blog">
                  <ListItemText>Blog</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/kontakt">
                  <ListItemText>Kontakt</ListItemText>
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid
            item
            xs={4}
            lg={6}
            className={classNames('text-align-center', c.socialIconsWrapper)}
          >
            <SocialIcon
              fontSize={40}
              href={'https://www.facebook.com/uljedivljeruze'}
              logo={'facebook'}
            />
            <SocialIcon
              fontSize={40}
              href={'https://www.instagram.com/plemenita_ulja/'}
              logo={'instagram'}
            />
          </Grid>

          <Grid item xs={12}>
            <List className={classNames(c.list)}>
              <ListItem>
                <Link to="/dostava-i-placanje">
                  <ListItemText>Dostava i plaćanje</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/reklamacija-i-povracaj-robe">
                  <ListItemText>Reklamacija i povraćaj robe</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/zastita-privatnosti">
                  <ListItemText>Zaštita privatnosti</ListItemText>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/prava-i-obaveze-potrosaca">
                  <ListItemText>Prava i obaveze potrošača</ListItemText>
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} className={classNames('text-align-center')}>
            <Typography gutterBottom={true}>
              ©{currentDate.getFullYear()} Copyright{' '}
              <a
                href="https://www.3stepsroadmap.com/"
                rel="noreferrer noopener"
                className={c.copyRight}
              >
                3 Steps Roadmap
              </a>{' '}
              All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  socialIconsWrapper: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  list: {
    '& li': {
      [theme.breakpoints.up('sm')]: {
        display: 'inline',
      },
    },
  },
  copyRight: {
    fontWeight: 700,
  },
  copyRightWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default Footer
