import { ORDER_CART_REQUEST, ORDER_CART_SUCCESS, ORDER_CART_FAIL } from 'shared/constants/orderConstants';

export const orderReducer = (state = { loading: false, products: [] }, action) => {
    switch (action.type) {
        case ORDER_CART_REQUEST:
            return { loading: true, products: [] };
        case ORDER_CART_SUCCESS:
            return { loading: false, products: action.payload };
        case ORDER_CART_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}