import Axios from "axios";
import axiosUrl from "libs/axiosUrl";
import { ALL_ARTICLES_REQUEST, ALL_ARTICLES_SUCCESS, ALL_ARTICLES_FAIL, ARTICLE_DETAILS_REQUEST, ARTICLE_DETAILS_SUCCESS, ARTICLE_DETAILS_FAIL } from 'shared/constants/articleConstant';

export const getAllArticles = () => async (dispatch) => {
    dispatch({ type: ALL_ARTICLES_REQUEST })

    try {
        const { data } = await Axios.get(`${axiosUrl}/articles`);
        dispatch({ type: ALL_ARTICLES_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ALL_ARTICLES_FAIL, payload: error.message })
    }
}

export const getDetailsArticle = (slug) => async (dispatch) => {
    dispatch({ type: ARTICLE_DETAILS_REQUEST })

    try {
        const { data } = await Axios.get(`${axiosUrl}/articles/?slug=${slug}`);
        dispatch({ type: ARTICLE_DETAILS_SUCCESS, payload: data[0] })
    } catch (error) {
        dispatch({
            type: ARTICLE_DETAILS_FAIL,
            payload: error.response &&
                error.response.data.message ?
                error.response.data.message :
                error.message
        })
    }
}