import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

// colors
const primary = "#e35683";
const secondary = "rgb(242, 242, 242)";
const text = "#8c8c8c";
const green = "#4caf50";
const background = "#FAF5EA";
const background2 = "#f8fbfa";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

// headings
const h1 = "48px !important";
const h2 = "32px !important";
const h3 = "24px !important";
const h4 = "20px !important";
const h5 = "16px !important";
const h6 = "14px !important";

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      white: "#fff",
      gray: '#E5E5E5',
      green: green,
      text: text
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: background,
    background2: background2,
    spacing
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs,
      sm,
      md,
      lg,
      xl,
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        width: 'unset',
      }
    },
    MuiListItemText: {
      root: {
        paddingLeft: 15
      }
    }
  },
  MuiTypography: {
    h1: {
      fontSize: h1,

    },
    h2: {
      fontSize: h2,
    },
    h3: {
      fontSize: h3,
    },
    h4: {
      fontSize: h4,
    },
    h5: {
      fontSize: h5,
    },
    h6: {
      fontSize: h6,
    }
  },
  MuiInput: {

  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Lora'
    ],
    body1: {
      fontSize: "1.3 rem"
    },
    body2: {
      fontSize: "1.1 rem",
    },
    h1: {
      fontSize: h1,
      fontFamily: `'Montserrat', sans-serif !important`,
    },
    h2: {
      fontSize: h2,
      fontFamily: `'Montserrat', sans-serif !important`,
    },
    h3: {
      fontSize: h3,
      fontFamily: `'Montserrat', sans-serif !important`,
    },
    h4: {
      fontSize: h4,
      fontFamily: `'Montserrat', sans-serif !important`,
    },
    h5: {
      fontSize: h5,
      fontFamily: `'Montserrat', sans-serif !important`,
    },
    h6: {
      fontSize: h6,
      fontFamily: `'Montserrat', sans-serif !important`,
    },
  }
});

export default responsiveFontSizes(theme);