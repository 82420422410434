import createSendingData from 'ConversionAPIs/utils/createSendingData';
import { event } from './fbpixel';

export default async function Purchase(values) {
  const additionalData = {};
  const eventId = crypto.randomUUID();
  const sendData = await createSendingData(values, eventId);

  event('Purchase', additionalData, { eventID: eventId });
  fetch(
    `https://graph.facebook.com/v18.0/${process.env.REACT_APP_PIXEL_ID}/events?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [sendData],
      }),
    }
  )
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error('Error:', error));
}
