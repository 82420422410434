import React from 'react';
import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';

import Photo from 'shared/assets/img/ulje-na-masnu-kozu-mix.jpg';
import theme from 'libs/theme/theme';

const TipSection = ({ imgPosition, image = false, qa }) => {
    const c = useStyles();
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Grid
            container
            justify='space-between'
            className="padding-spacing-2 bkg-cover mb4 textColor"
            component="article"
        >
            {
                (imgPosition === 'left') ? (
                    <Grid item xs={12} md={4} className={classNames(c.imgWrapper, isLg ? "text-align-start" : "text-align-center")}>
                        <img src={image ? image : Photo} alt="some description" />
                    </Grid>
                ) : (
                    null
                )
            }

            <Grid item xs={12} md={8} className={classNames(c.tipSectionWrapper)}>
                <Typography color="primary" variant="h2" gutterBottom={true} className={classNames(c.tipSectionTitle, "text-align-center")}>
                    {qa.title}
                </Typography>
                <Typography variant="body1">
                    {qa.text}
                </Typography>
            </Grid>

            {
                (imgPosition === 'right') ? (
                    <Grid item xs={12} md={4} className={classNames(c.imgWrapper, isLg ? "text-align-end" : "text-align-center")}>
                        <img src={image ? image : Photo} alt="some description" />
                    </Grid>
                ) : (
                    null
                )
            }
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    tipSectionWrapper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    tipSectionTitle: {
        fontWeight: 600,
    },
    imgWrapper: {
        padding: theme.spacing(1),
        "& img": {
            maxWidth: "100%",
            width: "600px",
            height: "auto",
        }
    }
}))

export default TipSection;